/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Button } from 'antd'
import { Link } from 'react-router-dom'

const CreateAndBackButtons = ({ adress, onCancel }) => {
  return (
    <div className='add-new-processor_buttons'>
      {adress ? (
        <Button
          className='addClient_form-item addClient_form-button'
          type='primary'
          style={{
            background: '#1890ff',
            color: '#fff',
            borderRadius: '5px',
            padding: '5px',
            height: '40px',
            width: '100px',
            fontWeight: 'bold',
            marginRight: '5px',
          }}
        >
          <Link to={adress}>
            <i className='zmdi zmdi-arrow-left mr-10'></i>
            Back
          </Link>
        </Button>
      ) : (
        <Button
          onClick={() => onCancel()}
          className='addClient_form-item addClient_form-button'
          type='primary'
          style={{
            background: '#1890ff',
            color: '#fff',
            borderRadius: '5px',
            padding: '5px',
            height: '40px',
            width: '100px',
            fontWeight: 'bold',
            marginRight: '5px',
          }}
        >
          <a>
            <i className='zmdi zmdi-arrow-left mr-10'></i>
            Back
          </a>
        </Button>
      )}
      <Button
        className='addClient_form-item addClient_form-button'
        type='submit'
        style={{
          background: 'green',
          color: '#fff',
          borderRadius: '5px',
          padding: '5px',
          height: '40px',
          width: '100px',
          fontWeight: 'bold',
          marginRight: '5px',
        }}
        htmlType='submit'
      >
        <i className='zmdi zmdi-plus mr-10' />
        Create
      </Button>
    </div>
  )
}

export default CreateAndBackButtons
