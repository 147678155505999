/* eslint-disable array-callback-return */
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Col, Row, Form, Drawer, Checkbox } from 'antd'
import * as actions from '../actions'
import { UserAddOutlined } from '@ant-design/icons'

const AddUserToCompany = ({ campaign, users, userCampaigns, addUserFromCampaign, removeUserFromCampaign }) => {
  const [form] = Form.useForm()
  const [isVisible, setVisible] = useState(false)

  const onChange = value => {
    if (value.target.checked) {
      addUserFromCampaign({
        userId: +value.target.value,
        campaignId: +campaign.id,
      })
    } else {
      removeUserFromCampaign({
        userId: +value.target.value,
        campaignId: +campaign.id,
      })
    }
  }

  return (
    <div>
      <UserAddOutlined
        style={{
          color: 'green',
          fontSize: '16px',
          cursor: 'pointer',
        }}
        title='add user'
        onClick={() => setVisible(true)}
      />

      <Drawer
        title='Add User'
        className={'add_product_popup'}
        onClose={() => setVisible(false)}
        visible={isVisible}
        forceRender
      >
        <Form layout='vertical' hideRequiredMark form={form}>
          <Form.Item name='userId' label='Users' rules={[{ required: true, message: 'Select user' }]}>
            {users.map((item, key) => {
              let existUser = userCampaigns.find(el => el.userId == item.id && el.campaignId == campaign.id)
              if (item.id === campaign.userId || existUser) {
                return (
                  <Col span={20} key={key}>
                    <Checkbox
                      defaultChecked
                      onChange={onChange}
                      value={item.id}
                      style={{
                        lineHeight: '32px',
                      }}
                    >
                      {item.id} - {item.firstName}
                    </Checkbox>
                  </Col>
                )
              } else {
                return (
                  <Col span={20} key={key}>
                    <Checkbox
                      onChange={onChange}
                      value={item.id}
                      style={{
                        lineHeight: '32px',
                      }}
                    >
                      {item.id} - {item.firstName}
                    </Checkbox>
                  </Col>
                )
              }
            })}
          </Form.Item>

          <div
            style={{
              position: 'absolute',
              left: 0,
              display: 'flex',
              justifyContent: 'space-between',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
            }}
          ></div>
        </Form>
      </Drawer>
    </div>
  )
}

const mapStateToProps = store => ({})

const mapDispatchToPtops = {
  addUserFromCampaign: actions.addUserFromCampaign,
  removeUserFromCampaign: actions.removeUserFromCampaign,
}

export default connect(mapStateToProps, mapDispatchToPtops)(AddUserToCompany)
