import axios from '../../utils/axios'
import settings from '../../settings'
import { types } from './types'
import { guardFromErrors, extractErrorInfo } from '../../utils/graphqlHelper'
import { errorNotification, successNotification } from '../../utils/notificationHelper'

const SET_TASKS_LIST = `
  query tasksListPage($page: Int, $size: Int, $filters: TasksFilters!) {
    tasksListPage(page: $page, size: $size, filters: $filters) {
      items {
        id
        companyId
        externalSiteId
        externalProductId
        changeProductExId
        type
        name
        status
        runDate
        startDate
        lookBackDays
        attempts
        attemptDelay
        nextCycleSameAsLast
        chargeCanceled
        chargeAllProducts
        notUseBalancer
        minDelay
        maxDelay
        runningMid
        customLenght
        successCustom
        unsuccessCustom
        cascadeMidActive
        decline
        declineAmount
        successAmount
        paayUse
        successRate
        controlDailyCap
      }
      total
    }
  }
`

const SET_TASK_CUSTOMER_PRODUCTS_LIST = `
  query TaskStatCustomerProductsListPage($page: Int, $size: Int, $filters: taskStatCustomerProductsFiltersType!) {
    TaskStatCustomerProductsListPage(page: $page, size: $size, filters: $filters) {
      items {
        id
        taskId
        customerId
        productId
        reason
        currentStatus
        shargeStatus
        attempt
        cycleNum
        nextChargeDate
      }
      total
    }
  }
`

const REMOVE_TASK = `
  mutation removeTask($taskId: ID!) {
    removeTask(taskId: $taskId)
  }
`

const CREATE_TASK = `
  mutation createTask(
    $name: String!, 
    $typeTask: String!, 
    $companyId: String!, 
    $extSiteId: String, 
    $lookBackDays: String!, 
    $startDate: String!, 
    $externalProductId: String, 
    $changeProductExId: String, 
    $attempts: Int, 
    $attemptDelay: Int, 
    $successRate: Int, 
    $nextCycleSameAsLast: Boolean, 
    $chargeCanceled: Boolean, 
    $notUseBalancer: Boolean, 
    $chargeAllProducts: Boolean, 
    $paayUse: Boolean, 
    $controlDailyCap: Boolean, 
    $cascadeMidActive: Boolean, 
    $minDelay: Int!, 
    $maxDelay: Int!) 
    {
    createTask(
      name: $name, 
      typeTask: $typeTask, 
      companyId: $companyId, 
      extSiteId: $extSiteId, 
      lookBackDays: $lookBackDays, 
      startDate: $startDate, 
      externalProductId: $externalProductId, 
      changeProductExId: $changeProductExId, 
      attempts: $attempts, 
      attemptDelay: $attemptDelay, 
      nextCycleSameAsLast: $nextCycleSameAsLast, 
      notUseBalancer: $notUseBalancer, 
      chargeAllProducts: $chargeAllProducts, 
      cascadeMidActive: $cascadeMidActive,
      chargeCanceled: $chargeCanceled, 
      paayUse: $paayUse, 
      successRate: $successRate, 
      minDelay: $minDelay, 
      controlDailyCap: $controlDailyCap, 
      maxDelay: $maxDelay) 
    {
    id
    }
  }
`

const CREATE_CYCLE = `
  mutation createCycle(
    $taskName: String!, 
    $number: Int, 
    $productId: Int, 
    $productPrice: String, 
    $type: String, 
    $cyclePeriod: Int) {
    createCycle(
      taskName: $taskName, 
      number: $number,
      productId: $productId,
      type: $type,
      productPrice: $productPrice, 
      cyclePeriod: $cyclePeriod) 
    {
    id
    }
  }
`

const UPDATE_CYCLE = `
  mutation updateCycle(
    $cycleId: ID!,
    $productPrice: String, 
    $cyclePeriod: Int) {
      updateCycle(
        cycleId: $cycleId,
      productPrice: $productPrice, 
      cyclePeriod: $cyclePeriod)
  }
`

const SEND_TASK = `
  mutation sendTask(
    $taskName: String!, 
    $action: String,
    $csvCustomers: [CustomerInput],
    ) {
    sendTask(taskName: $taskName, action: $action, csvCustomers: $csvCustomers) {
      id
    }
  }
`
const UPDATE_TASK = `
  mutation updateTask(
    $taskId: ID!,
    $name: String!,
    $lookBackDays: String,
    $startDate: String,
    $successRate: String,
    $attempts: Int,
    $attemptDelay: Int,
    $nextCycleSameAsLast: Boolean,
    $notUseBalancer: Boolean,
    $chargeCanceled: Boolean,
    $chargeAllProducts: Boolean,
    $paayUse: Boolean,
    $controlDailyCap: Boolean,
    $cascadeMidActive: Boolean,
    $minDelay: Int!,
    $maxDelay: Int!) {
    updateTask(taskId: $taskId, name: $name, startDate: $startDate, lookBackDays: $lookBackDays, attempts: $attempts, attemptDelay: $attemptDelay, nextCycleSameAsLast: $nextCycleSameAsLast, chargeCanceled: $chargeCanceled, minDelay: $minDelay, maxDelay: $maxDelay, successRate: $successRate, notUseBalancer: $notUseBalancer, chargeAllProducts: $chargeAllProducts, paayUse: $paayUse, controlDailyCap: $controlDailyCap,cascadeMidActive: $cascadeMidActive,)
  }
`

const UPDATE_STATUS_TASK = `
  mutation updateStatusTask($taskId: ID!, $action: String!) {
    updateStatusTask(taskId: $taskId, action: $action) {
      status
    }
  }
`

const GET_STATUS_DATE_START = `
  query task($taskId: ID!) {
    task(taskId: $taskId) {
      id
      status
      runningMid
      runDate
    }
  }
`

const GET_CUSTOMERS_INFO = `
  query task($taskId: ID!) {
    task(taskId: $taskId) {
      id
      customLenght
      successCustom
      unsuccessCustom
      runningMid
    }
  }
`

const SET_CYCLES = `
  query tasksListPage($page: Int, $size: Int, $filters: CyclesFilters!) {
    cyclesListPage(page: $page, size: $size, filters: $filters) {
      items {
        id
        taskId
        number
        productPrice
        productId
        cyclePeriod
      }
      total
    }
  }
`

const DELETE_CYCLE = `
  mutation removeCycle($cycleId: ID!) {
    removeCycle(cycleId: $cycleId)
  }
`

const CREATE_TASK_STAT = `
  mutation statTask($taskId: ID!) {
    statTask(taskId: $taskId) {
      id
      name
      status
      successCustom
      decline
      declineAmount
      successAmount
      canceled
      problem
      total
    }
  }
`

const CREATE_TASK_CUSTOMERS_STAT = `
  mutation createCustomersStat($taskId: ID!, $action: String){
    createCustomersStat(taskId: $taskId, action: $action)
  }
`

const FETCH_TASK_CUSTOMERS_STAT = `
  query customersStatList($page: Int, $size: Int, $filters:  customersStatFiltersType!) {
    customersStatList(page: $page, size: $size, filters: $filters) {
      items {
        id
        taskId
        externalId
        currentStatus
        reason
        shargeStatus
        attempt
        cycleNum
        log
        nextChargeDate
      }
      total
    }
  }
`

// setTaskCustomersProductsList
export const setTaskCustomersProductsList =
  ({ page, size, filters = {} }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })

      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: SET_TASK_CUSTOMER_PRODUCTS_LIST,
        variables: { page, size, filters },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is ampty!')
      }

      const { TaskStatCustomerProductsListPage } = data
      if (!TaskStatCustomerProductsListPage) {
        throw new Error("Can't get Products")
      }

      dispatch({
        type: types.SET_TASK_CUSTOMER_PRODUCTS_LIST,
        data: TaskStatCustomerProductsListPage,
      })

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      dispatch(errorNotification(errorMessage))
    }
  }

//setTaskStatistics
export const taskStat =
  ({ taskId }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })

      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: CREATE_TASK_STAT,
        variables: { taskId },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is ampty!')
      }

      const { statTask } = data
      if (!statTask) {
        throw new Error("Can't get statistics")
      }

      dispatch({
        type: types.SET_TASK_STATISTICS,
        data: statTask,
      })

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      dispatch(errorNotification(errorMessage))
    }
  }

//createOrUpdateTaskCustomersStat
export const createOrUpdateTaskCustomersStat =
  ({ taskId, action }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })

      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: CREATE_TASK_CUSTOMERS_STAT,
        variables: { taskId, action },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is ampty!')
      }

      const { createCustomersStat } = data
      if (!createCustomersStat) {
        throw new Error("Can't get statistics")
      }

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      dispatch(errorNotification(errorMessage))
    }
  }

// setTaskCustomersStatList
export const setTaskCustomersStatList =
  ({ page, size, filters = {} }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })

      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: FETCH_TASK_CUSTOMERS_STAT,
        variables: { page, size, filters },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is ampty!')
      }

      const { customersStatList } = data
      if (!customersStatList) {
        throw new Error("Can't get Customers")
      }

      dispatch({
        type: types.SET_TASK_CUSTOMERS_STATISTICS,
        data: customersStatList,
      })

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      dispatch(errorNotification(errorMessage))
    }
  }

// deleteCycle
export const deleteCycle =
  ({ cycleId }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })

      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: DELETE_CYCLE,
        variables: { cycleId },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is ampty!')
      }

      const { removeCycle } = data
      if (!removeCycle) {
        throw new Error("Can't remove cycle")
      }

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      dispatch(errorNotification(errorMessage))
    }
  }

// setTasksList
export const setTasksList =
  ({ page, size, filters = {} }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })

      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: SET_TASKS_LIST,
        variables: { page, size, filters },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is ampty!')
      }

      const { tasksListPage } = data
      console.log(tasksListPage)
      if (!tasksListPage) {
        throw new Error("Can't get tasks")
      }

      dispatch({
        type: types.SET_TASKS_LIST,
        data: tasksListPage,
      })

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      dispatch(errorNotification(errorMessage))
    }
  }

// setCycles
export const setCycles =
  ({ page, size, filters = {} }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })

      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: SET_CYCLES,
        variables: { page, size, filters },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is ampty!')
      }

      const { cyclesListPage } = data
      if (!cyclesListPage) {
        throw new Error("Can't get cycless")
      }

      dispatch({
        type: types.SET_CYCLES,
        data: cyclesListPage.items,
      })

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      dispatch(errorNotification(errorMessage))
    }
  }

//remove task
export const removeTask = taskId => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: REMOVE_TASK,
      variables: { taskId },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { removeTask } = data
    if (!removeTask) {
      throw new Error("Can't remove Task")
    }

    dispatch(successNotification('Remove Task successful'))

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
  }
}

//add new task
export const addNewTask = formData => async dispatch => {
  try {
    let startDateFormat
    if (formData.startDate) {
      let date = new Date(formData.startDate._d.toString())
      const month = date.getMonth() + 1 <= 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
      let day = date.getDate() <= 9 ? '0' + date.getDate() : date.getDate()
      let hours = date.getHours() <= 9 ? '0' + date.getHours() : date.getHours()
      let minutes = date.getMinutes() <= 9 ? '0' + date.getMinutes() : date.getMinutes()
      let seconds = date.getSeconds() <= 9 ? '0' + date.getSeconds() : date.getSeconds()
      startDateFormat = date.getUTCFullYear() + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds
    } else {
      let date = new Date().toLocaleString('en-US', {
        timeZone: 'America/New_York',
        hour12: false,
      })
      let res = date.split('/')
      let month = res[0].length === 1 ? `0${res[0]}` : `${res[0]}`
      let dey = res[1].length === 1 ? `0${res[1]}` : `${res[1]}`
      let splitData = res[2].split(', ')
      let needDate = `${splitData[0]}-${month}-${dey} ${splitData[1]}`
      startDateFormat = needDate
    }

    formData.lookBackDays ? (formData.lookBackDays = formData.lookBackDays.toString()) : (formData.lookBackDays = '0')

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: CREATE_TASK,
      variables: {
        companyId: formData.companyId.toString(),
        extSiteId: formData.extSiteId,
        minDelay: formData.minDelay,
        maxDelay: formData.maxDelay,
        typeTask: formData.typeTask,
        name: formData.name,
        lookBackDays: formData.lookBackDays,
        attempts: formData.attempts,
        attemptDelay: formData.attemptDelay,
        nextCycleSameAsLast: formData.nextCycleSameAsLast,
        chargeCanceled: formData.chargeCanceled,
        notUseBalancer: formData.notUseBalancer,
        chargeAllProducts: formData.chargeAllProducts,
        paayUse: formData.paayUse,
        successRate: formData.successRate,
        startDate: startDateFormat,
        controlDailyCap: formData.controlDailyCap,
        cascadeMidActive: formData.cascadeMidActive,
      },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createTask } = data
    if (!createTask) {
      throw new Error("Can't create Task")
    }
    return true
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
  }
}

//createCycle
export const createCycle = formData => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const existCycles = []

    for (const prop in formData) {
      for (let i = 0; i < 41; i++) {
        if (prop === `cycle${i}`) {
          existCycles.push(formData[prop])
        }
      }
    }

    for (let i = 0; i < existCycles.length; i++) {
      for (let j = 0; j < existCycles[i].length; j++) {
        let cyclePeriod = 0
        if (!existCycles[i].period) {
          cyclePeriod = 30
        } else {
          cyclePeriod = existCycles[i][j].period
        }
        let { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
          query: CREATE_CYCLE,
          variables: {
            taskName: formData.name,
            number: existCycles[i][j].number,
            cyclePeriod: cyclePeriod,
            type: existCycles[i][j].type,
            productId: +existCycles[i][j].productId,
            productPrice: existCycles[i][j].price,
          },
        })

        guardFromErrors(errors)

        if (!data) {
          throw new Error('Response body is empty!')
        }

        const { createCycle } = data
        if (!createCycle) {
          throw new Error("Can't create Cycle")
        }
      }
    }

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })

    return true
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
  }
}

//createCycleUpdateTask
export const createCycleUpdateTask = formData => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    let { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: CREATE_CYCLE,
      variables: {
        taskName: formData.taskName,
        number: formData.number,
        cyclePeriod: formData.period,
        productPrice: formData.price,
      },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createCycle } = data
    if (!createCycle) {
      throw new Error("Can't create Cycle")
    }

    dispatch(successNotification('Create Cycle successful'))

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
  }
}

//updateCycleInUpdateTask
export const updateCycleInUpdateTask = formData => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    let { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: UPDATE_CYCLE,
      variables: {
        cycleId: +formData.cycleId,
        cyclePeriod: formData.period,
        productPrice: formData.price,
      },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateCycle } = data
    if (!updateCycle) {
      throw new Error("Can't update Cycle")
    }

    dispatch(successNotification('Update Cycle successful'))

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
  }
}

//sendTask
export const sendTask =
  ({ taskName, action, csvCustomers }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })

      const needsCsv = csvCustomers.map(item => {
        return {
          amount: item.amount,
          campaignId: item.campaignId,
          externalId: item.externalId,
          externalSiteId: item.externalSiteId,
          productId: item.productId,
          recurringId: item.recurringId,
          cascadeMid: item.cascadeMid,
        }
      })

      const sendTask = await axios.post(`${settings.backendUrl}/graphql`, {
        query: SEND_TASK,
        variables: {
          taskName,
          action,
          csvCustomers: [...needsCsv],
        },
      })

      guardFromErrors(sendTask.errors)

      if (!sendTask.data) {
        throw new Error('Response body is empty!')
      }

      if (!sendTask.data.sendTask) {
        throw new Error("Can't send Task")
      }

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })

      dispatch(successNotification('Create Task successful'))

      return true
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      dispatch(errorNotification(errorMessage))
    }
  }

//update Task
export const updateTask = args => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })
    const {
      taskId,
      name,
      lookBackDays,
      startDate,
      attempts,
      attemptDelay,
      successRate,
      nextCycleSameAsLast,
      minDelay,
      maxDelay,
      chargeAllProducts,
      chargeCanceled,
      notUseBalancer,
      controlDailyCap,
      paayUse,
      cascadeMidActive,
    } = args

    let date = new Date(startDate._d.toString())
    const month = date.getMonth() + 1 <= 9 ? '0' + date.getMonth() : date.getMonth() + 1
    const day = date.getDate() <= 9 ? '0' + date.getDate() : date.getDate()
    const hours = date.getHours() <= 9 ? '0' + date.getHours() : date.getHours()
    const minutes = date.getMinutes() <= 9 ? '0' + date.getMinutes() : date.getMinutes()
    const seconds = date.getSeconds() <= 9 ? '0' + date.getSeconds() : date.getSeconds()
    let startDateFormat = date.getUTCFullYear() + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: UPDATE_TASK,
      variables: {
        taskId,
        name,
        lookBackDays,
        startDate: startDateFormat,
        attempts,
        attemptDelay,
        nextCycleSameAsLast,
        successRate: successRate,
        minDelay,
        maxDelay,
        paayUse,
        chargeAllProducts,
        notUseBalancer,
        chargeCanceled,
        controlDailyCap,
        cascadeMidActive,
      },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateTask } = data
    if (!updateTask) {
      throw new Error("Can't update Task")
    }

    dispatch(successNotification('Task update successful'))

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
  }
}

//updateStatusTask
export const updateStatusTask =
  ({ taskId, action }) =>
  async dispatch => {
    try {
      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: UPDATE_STATUS_TASK,
        variables: { taskId, action },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { updateStatusTask } = data
      if (!updateStatusTask) {
        throw new Error("Can't update status task!")
      }

      dispatch(successNotification('action task successful!'))

      return updateStatusTask
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      dispatch(errorNotification(errorMessage))
    }
  }

//getStatusAndStartDate Task
export const getStatusAndStartDate = taskId => async dispatch => {
  try {
    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: GET_STATUS_DATE_START,
      variables: { taskId },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { task } = data
    if (!task) {
      throw new Error("Can't get Task")
    }

    dispatch({
      type: types.SET_TASKS_STATUS_AND_DATE_START,
      data: {
        id: task.id,
        status: task.status,
        runDate: task.runDate,
        runningMid: task.runningMid,
      },
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
  }
}

//getCustomersInfo Task
export const getCustomersInfo = taskId => async dispatch => {
  try {
    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: GET_CUSTOMERS_INFO,
      variables: { taskId },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { task } = data
    if (!task) {
      throw new Error("Can't get Task")
    }

    dispatch({
      type: types.SET_CUSTOMERS_INFO,
      data: {
        id: task.id,
        customLenght: task.customLenght,
        successCustom: task.successCustom,
        unsuccessCustom: task.unsuccessCustom,
        runningMid: task.runningMid,
      },
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
  }
}
