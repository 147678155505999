import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button, Drawer, Input, Form } from 'antd'
import * as actions from '../actions'

const UpdateSite = ({ record, fetchSitesList, changeSite }) => {
  const [isVisible, setVisible] = useState(false)
  const [form] = Form.useForm()
  const [crmType, setCrmType] = useState(
    window.localStorage.getItem('crm') && JSON.parse(window.localStorage.getItem('crm')).value
      ? JSON.parse(window.localStorage.getItem('crm')).value
      : 'response',
  )

  const onFinishHandler = async values => {
    await changeSite({ siteId: values.id, name: values.name, url: values.url })
    fetchSitesList({ crmType: crmType })
    setVisible(false)
    form.resetFields()
  }

  return (
    <>
      <i
        className='zmdi zmdi-edit zmdi-hc-2x zmdi-hc-lg txt-warning users-list-action_item'
        title='Edit'
        type='primary'
        onClick={() => {
          setVisible(true)
        }}
      />
      <Drawer
        title='Update Site'
        className={'add_product_popup'}
        onClose={() => setVisible(false)}
        visible={isVisible}
        forceRender
      >
        <Form layout='vertical' hideRequiredMark onFinish={onFinishHandler} form={form}>
          <Form.Item
            initialValue={record.name}
            name='name'
            label='Name'
            rules={[{ required: true, message: 'Please input site name!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            initialValue={record.url}
            name='url'
            label='url'
            rules={[{ required: true, message: 'Please input site url!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item initialValue={record.externalId} name='id' label='id'>
            <Input disabled />
          </Form.Item>

          <div
            style={{
              position: 'absolute',
              left: 0,
              display: 'flex',
              justifyContent: 'space-between',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
            }}
          >
            <Button onClick={() => setVisible(false)}>Close</Button>
            <Button type='primary' htmlType='submit' className='add-new-site_button'>
              Update
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  )
}

const mapDispatchToProps = {
  fetchSitesList: actions.fetchSitesList,
  changeSite: actions.updateSite,
}

export default connect(null, mapDispatchToProps)(UpdateSite)
