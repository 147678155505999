import axios from '../../utils/axios'
import settings from '../../settings'
import { guardFromErrors, extractErrorInfo } from '../../utils/graphqlHelper'
import { errorNotification, successNotification } from '../../utils/notificationHelper'

const signUpQuery = `
  mutation SignUp($email: String!, $password: String!, $firstName: String!, $lastName: String!) {
    signUp(email: $email, password: $password, firstName: $firstName, lastName: $lastName)
  }
`

export const signUp =
  ({ firstName, lastName, email, password, history }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })

      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: signUpQuery,
        variables: { firstName, lastName, email, password },
      })
      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { signUp } = data
      if (!signUp) {
        throw new Error("Can't register!")
      }

      dispatch(successNotification('Registration is succeessfull!'))

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })

      history.push('/login')
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      dispatch(errorNotification(errorMessage))
    }
  }
