/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import useReactRouter from 'use-react-router'
import navigation from './navigation'
import { actions as loginActions } from '../../pages/Login'
import logo from '../../assets/images/logo.png'
import HeaderSelectCompany from '../HeaderSelectCompany'

const Main = ({ children, logOut, me }) => {
  const [redirect, setRedirect] = useState(false)
  const [isMenu, setIsMenu] = useState(true)
  const [activeNav, setActiveNav] = useState('-1')

  const { history } = useReactRouter()

  const role = me.roles.find(item => item === 'admin') || 'user'
  let navigationFiltered = navigation

  if (role !== 'admin') {
    navigationFiltered = navigationFiltered.filter(item => !item.adminOnly)
  }

  useEffect(() => {
    setRedirect({ active: false, href: '' })
  }, [])

  return (
    <div>
      {redirect.active && <Redirect to={redirect.href} />}
      <div className={`wrapper theme-5-active pimary-color-green ${!isMenu ? 'slide-nav-toggle' : ''}`}>
        <nav className='navbar navbar-inverse navbar-fixed-top'>
          <div className='header-admin_items'>
            <div className='mobile-only-brand pull-left'>
              <div className='nav-header pull-left'>
                <div className='logo-wrap'>
                  <Link to='/'>
                    <img className='brand-img' src={logo} alt='brand' />
                  </Link>
                </div>
              </div>
              <a onClick={() => setIsMenu(!isMenu)} className='toggle-left-nav-btn inline-block ml-60 pull-left'>
                <i className='zmdi zmdi-menu' />
              </a>
            </div>
            <div>
              <HeaderSelectCompany />
            </div>
            <div id='mobile_only_nav' className='mobile-only-nav pull-right header-sey-hello'>
              <ul className='nav navbar-right top-nav pull-right'>
                <li>
                  <a>
                    Hello, {me.firstName} {me.lastName}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <div className='fixed-sidebar-left'>
          <div className='slimScrollDiv'>
            <ul className='nav navbar-nav side-nav nicescroll-bar' style={{ overflow: 'hidden' }}>
              {navigationFiltered.map(navItem => {
                const additionalProps = !!navItem.href
                  ? {
                      onClick: () => setRedirect({ active: true, href: navItem.href }),
                    }
                  : {}
                return (
                  <li key={navItem.id}>
                    <a
                      className={activeNav === navItem.id ? 'collapsed' : ''}
                      onClick={() => setActiveNav(activeNav === navItem.id ? '-1' : navItem.id)}
                      data-toggle='collapse'
                      data-target='#ecom_dr'
                      {...additionalProps}
                      aria-expanded={activeNav === navItem.id ? 'true' : 'false'}
                    >
                      <div className='pull-left'>
                        <i className={navItem.icon} />
                        <span className='right-nav-text' title={navItem.title}>
                          {navItem.name}
                        </span>
                      </div>
                      <div className='clearfix' />
                    </a>
                    {!!navItem.subRoutes && (
                      <ul id='ecom_dr' className={`collapse collapse-level-1 ${activeNav === navItem.id ? 'in' : ''}`}>
                        {!!navItem.subRoutes.length &&
                          navItem.subRoutes.map(subRoute => (
                            <li key={subRoute.id}>
                              <Link to={subRoute.href}>{subRoute.name}</Link>
                            </li>
                          ))}
                      </ul>
                    )}
                  </li>
                )
              })}
              <li>
                <a data-toggle='collapse' data-target='#ecom_dr' onClick={() => logOut(history)}>
                  <div className='pull-left'>
                    <i className='zmdi zmdi-power mr-20' />
                    <span className='right-nav-text'>Logout</span>
                  </div>
                  <div className='clearfix' />
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className='page-wrapper' style={{ minHeight: '976px', background: '#fff' }}>
          <div className='container-fluid pt-25'>{children}</div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = store => ({
  me: store.login,
})

const mapDispatchToProps = {
  logOut: loginActions.logOut,
}

export default connect(mapStateToProps, mapDispatchToProps)(Main)
