import { types } from './types'

const initState = {
  list: [],
  usersForOptions: [],
  clientInfo: {},
  total: 0,
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case types.SET_CLIENTS_LIST: {
      const { data } = action
      return {
        ...state,
        list: data.items,
        total: data.total,
      }
    }
    case 'LOG_OUT': {
      return { ...initState }
    }
    case types.SET_CLIENT_LIST: {
      const { data } = action
      return {
        ...state,
        clientInfo: data.items,
      }
    }
    case types.REMOVE_USER: {
      state.list = state.list.filter(item => item.id !== action.data)
      state.total = state.total === 0 ? state.total : state.total - 1
      return {
        ...state,
      }
    }
    case 'SET_USERS_FOR_OPTIONS': {
      const { data } = action
      return {
        ...state,
        usersForOptions: data,
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
