import React, { useEffect, useState } from 'react'
import { Table, Popconfirm, Button } from 'antd'
import { Main } from '../../../components'
import * as actions from '../actions'
import { connect } from 'react-redux'
import { UpdateScrub } from '../components'
import CreateScrub from '../components/CreateScrub'

const ScrubsList = ({ setScrubsList, total, scrubsList, removeScrub, compaignId, compaigns }) => {
  const [pageSize, setPageSize] = useState(10)
  const [pageNumber, setPageNumber] = useState(1)
  const [crmType, setCrmType] = useState(
    window.localStorage.getItem('crm') && JSON.parse(window.localStorage.getItem('crm')).value
      ? JSON.parse(window.localStorage.getItem('crm')).value
      : 'response',
  )

  useEffect(() => {
    const campaignItem = JSON.parse(window.localStorage.getItem('campaign'))
    if (campaignItem !== '' && campaignItem) {
      setScrubsList({
        page: pageNumber,
        size: pageSize,
        filters: { campaign_id: campaignItem.id },
      })
    } else {
      setScrubsList({
        page: pageNumber,
        size: pageSize,
        filters: {},
      })
    }
  }, [pageSize, pageNumber, compaignId])

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'SiteID',
      dataIndex: 'site_id',
      key: 'site_id',
    },
    {
      title: 'AffID',
      dataIndex: 'aff_id',
      key: 'aff_id',
    },
    {
      title: 'ProductID',
      dataIndex: 'product_id',
      key: 'product_id',
    },
    {
      title: 'Fire Percent',
      dataIndex: 'percent',
      key: 'percent',
    },
    {
      title: 'Actions',
      dataIndex: '',
      key: '',
      render: record => (
        <>
          <UpdateScrub record={record} />
          <Popconfirm
            title='Are you sure delete this Scrub?'
            onConfirm={e => doConfirm(record.id, e)}
            onCancel={e => e?.stopPropagation()}
            okText='Yes'
            cancelText='No'
          >
            <i className='zmdi zmdi-delete zmdi-hc-2x txt-danger users-list-action_item' href='/#' title='Delete'></i>
          </Popconfirm>
        </>
      ),
    },
  ]

  const doConfirm = (scrubId, e) => {
    e && e.stopPropagation()
    removeScrub(scrubId)
    if (compaignId !== '') {
      setScrubsList({
        page: pageNumber,
        size: pageSize,
        filters: { campaign_id: +compaignId },
      })
    } else {
      setScrubsList({ page: pageNumber, size: pageSize, filters: {} })
    }
  }

  const changePaginataion = data => {
    const { current, pageSize: paginationSize } = data
    setPageSize(paginationSize)
    setPageNumber(current)
  }

  return (
    <Main>
      <h4 className='txt-dart'>Scrubs</h4>
      <div>
        <CreateScrub />
      </div>
      <Table
        columns={columns}
        dataSource={scrubsList}
        bordered
        onChange={changePaginataion}
        rowKey={record => record.id}
        scroll={{ x: 768, y: 10000 }}
        pagination={{
          showSizeChanger: true,
          pageSize,
          current: pageNumber,
          total,
        }}
      />
    </Main>
  )
}

const mapStateToProps = store => ({
  scrubsList: store.scrubs.list,
  total: store.scrubs.total,
  compaigns: store.companies.list,
  compaignId: store.companies.compaign,
})

const mapDispatchToProps = {
  setScrubsList: actions.setScrubsList,
  removeScrub: actions.removeScrub,
}

export default connect(mapStateToProps, mapDispatchToProps)(ScrubsList)
