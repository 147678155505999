import { types } from './types'

const initState = {
  list: [],
  total: 0,
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case types.SET_PROCESSORS_LIST: {
      console.log('pp')
      const { data } = action
      console.log('data: ', data)
      return {
        ...state,
        list: data.items,
        total: data.total,
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
