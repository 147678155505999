import axios from '../../utils/axios'
import settings from '../../settings'
import { types } from './types'
import { guardFromErrors, extractErrorInfo } from '../../utils/graphqlHelper'
import { errorNotification, successNotification } from '../../utils/notificationHelper'

const SET_SCRUBS_LIST = `
query scrubsListPage($page: Int, $size: Int, $filters: ScrubFilters!) {
  scrubsListPage(page: $page, size: $size, filters: $filters) {
    items {
      id
      campaign_id
      site_id
      percent
      product_id
      aff_id
          }
    total
  }
}
`

const REMOVE_SCRUB = `
  mutation removeScrub($scrubId: ID!) {
    removeScrub(scrubId: $scrubId)
  }
`

const UPDATE_SCRUB = `
  mutation updateScrub($scrubId: ID!, $affId: String, $productId: Int, $percent: Int!, $campaignId: Int!, $siteId: Int!) {
    updateScrub(scrubId: $scrubId, affId: $affId, productId: $productId, percent: $percent, campaignId: $campaignId, siteId: $siteId)
  }
`

const CREATE_SCRUB = `
  mutation createScrub($affId: String, $productId: Int, $percent: Int!, $campaignId: Int!, $siteId: Int!) {
    createScrub(affId: $affId, productId: $productId, percent: $percent, campaignId: $campaignId, siteId: $siteId)
  }
`

//setScrubsList
export const setScrubsList =
  ({ page, size, filters = {} }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })

      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: SET_SCRUBS_LIST,
        variables: { page, size, filters },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { scrubsListPage } = data
      if (!scrubsListPage) {
        throw new Error("Can't get scrubs!")
      }

      dispatch({
        type: types.SET_SCRUBS_LIST,
        data: scrubsListPage,
      })

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      dispatch(errorNotification(errorMessage))
    }
  }

//remove Scrub
export const removeScrub = scrubId => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: REMOVE_SCRUB,
      variables: { scrubId },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { removeScrub } = data
    if (!removeScrub) {
      throw new Error("Can't remove Scrub")
    }

    dispatch({
      type: types.REMOVE_SCRUB,
      data: scrubId,
    })

    dispatch(successNotification('Remove Scrub successful'))

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
  }
}

//update Scrub
export const updateScrub = args => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })
    console.log(args)
    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: UPDATE_SCRUB,
      variables: {
        scrubId: args.scrubId,
        siteId: args.siteId,
        campaignId: args.campaignId,
        percent: args.percent,
        affId: args.affId,
        productId: args.productId,
      },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateScrub } = data
    if (!updateScrub) {
      throw new Error("Can't update Scrub!")
    }

    dispatch(successNotification('Scrub update successful!'))

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log(errorMessage)
    dispatch(errorNotification(errorMessage))
  }
}

export const createScrub = args => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: CREATE_SCRUB,
      variables: {
        siteId: args.siteId,
        campaignId: args.campaignId,
        percent: args.percent,
        affId: args.affId,
        productId: args.productId,
      },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createScrub } = data
    if (!createScrub) {
      throw new Error("Can't update Scrub!")
    }

    dispatch(successNotification('Scrub update successful!'))

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
  }
}
