import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, Drawer, Input, Form, Select, InputNumber } from 'antd'
import * as actions from '../actions'
import * as compaignsActions from '../../Companies/actions'

const CopyGroup = ({ record, fetchCompaniesList, campaigns, copyCorp, setGroupList }) => {
  const [isVisible, setVisible] = useState(false)
  const [corpName, setCorpName] = useState('')
  const [form] = Form.useForm()
  const [crmType, setCrmType] = useState(
    window.localStorage.getItem('crm') && JSON.parse(window.localStorage.getItem('crm')).value
      ? JSON.parse(window.localStorage.getItem('crm')).value
      : 'response',
  )

  useEffect(() => {
    fetchCompaniesList({ page: 1, size: 100, crmType: crmType })
  }, [fetchCompaniesList])

  useEffect(() => {
    campaigns.map(item => {
      if (item.id === record.campaignId) {
        setCorpName(item.name)
      }
    })
  }, [])

  const onFinishHandler = async values => {
    await copyCorp({
      groupId: +values.groupId,
      name: values.name,
    })
    await setGroupList({ page: 1, size: 10, crmType: crmType, filters: { type: 'corp' } })
    setVisible(false)
    form.resetFields()
  }

  return (
    <>
      <i
        className='zmdi zmdi-copy zmdi-hc-2x zmdi-hc-lg txt-warning users-list-action_item'
        title='Copy Corporation'
        type='primary'
        onClick={() => {
          setVisible(true)
        }}
      />
      <Drawer
        title='Copy Corporation'
        className={'add_product_popup'}
        onClose={() => setVisible(false)}
        visible={isVisible}
        forceRender
      >
        <Form layout='vertical' hideRequiredMark onFinish={onFinishHandler} form={form}>
          <Form.Item initialValue={corpName} name='campaignId' label='Campaign Name'>
            <Input disabled />
          </Form.Item>

          <Form.Item initialValue={record.id} name='groupId' label='Copy from Corporation ID'>
            <Input disabled />
          </Form.Item>

          <Form.Item
            name='name'
            label='Input Corporation Name'
            rules={[{ required: true, message: 'Input Corporation Name!' }]}
          >
            <Input />
          </Form.Item>

          <div
            style={{
              position: 'absolute',
              left: 0,
              display: 'flex',
              justifyContent: 'space-between',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
            }}
          >
            <Button onClick={() => setVisible(false)}>Cancel</Button>
            <Button type='primary' htmlType='submit' className='add-new-site_button'>
              Copy
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  )
}

const mapStateToProps = store => ({
  campaigns: store.companies.list,
})

const mapDispatchToProps = {
  fetchCompaniesList: compaignsActions.fetchCompaniesList,
  copyCorp: actions.copyCorp,
  setGroupList: actions.setGroupList,
}

export default connect(mapStateToProps, mapDispatchToProps)(CopyGroup)
