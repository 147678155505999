import React from 'react'
import {
  createNotification,
  NOTIFICATION_TYPE_SUCCESS,
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_INFO,
} from 'react-redux-notify'

const processNotification =
  ({ message, type = 'info' }) =>
  dispatch => {
    let internalType, icon

    switch (type) {
      case 'info': {
        internalType = NOTIFICATION_TYPE_INFO
        icon = 'fa-exclamation'
        break
      }
      case 'error': {
        internalType = NOTIFICATION_TYPE_ERROR
        icon = 'fa-close'
        break
      }
      case 'success': {
        internalType = NOTIFICATION_TYPE_SUCCESS
        icon = 'fa-check'
        break
      }
      default: {
        internalType = NOTIFICATION_TYPE_INFO
        icon = 'fa-exclamation'
      }
    }

    if (type === 'error') {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    }

    return dispatch(
      createNotification({
        message: <p className='notifyMessage'>{message}</p>,
        type: internalType,
        duration: 5000,
        canDismiss: true,
        icon: <i className={`fa ${icon}`} />,
      }),
    )
  }

export const infoNotification = message => processNotification({ message, type: 'info' })
export const errorNotification = message => processNotification({ message, type: 'error' })
export const successNotification = message => processNotification({ message, type: 'success' })
