import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as actions from '../actions'

const StartCampaign = ({ record, updateStatusCampaign }) => {
  const updateStatus = async (id, action) => {
    const successStatus = await updateStatusCampaign({ id, action })
  }
  return (
    <>
      {!record.active && (
        <i
          className='zmdi zmdi-play-circle zmdi-hc-2x users-list-action_item'
          title='Start'
          type='primary'
          onClick={async () => {
            await updateStatus(record.id, 'run_campaign')
          }}
        />
      )}

      {record.active && (
        <i
          className='zmdi zmdi-stop zmdi-hc-3x zmdi-hc-lg users-list-action_item'
          title='Stop'
          type='primary'
          onClick={async () => {
            await updateStatus(record.id, 'stop_campaign')
          }}
        />
      )}
    </>
  )
}

const mapStateToProps = store => ({})

const mapDispatchToProps = {
  updateStatusCampaign: actions.updateStatusCampaign,
}

export default connect(mapStateToProps, mapDispatchToProps)(StartCampaign)
