import { createStore, combineReducers, compose, applyMiddleware } from 'redux'
import { createBrowserHistory } from 'history'
import thunk from 'redux-thunk'
import { routerMiddleware, connectRouter } from 'connected-react-router'
import * as Loader from '../components/Loader'
import * as Login from '../pages/Login'
import * as Clients from '../pages/Clients'
import * as Sites from '../pages/Sites'
import * as Companies from '../pages/Companies'
import * as Customers from '../pages/Customers'
import * as Processors from '../pages/Processors'
import * as Products from '../pages/Products'
import * as Pixels from '../pages/Pixels'
import * as Scrubs from '../pages/Scrub'
import * as Tasks from '../pages/Tasks'
import * as Rules from '../pages/Rules'
import * as Groups from '../pages/Groups'
import * as Corporations from '../pages/Corporations'
import * as RebatesCampaigns from '../pages/RebatesCampaigns'
import notifyReducer from 'react-redux-notify'

export const history = createBrowserHistory()

const reducers = history =>
  combineReducers({
    router: connectRouter(history),
    loading: Loader.reducer,
    login: Login.reducer,
    clients: Clients.reducer,
    sites: Sites.reducer,
    companies: Companies.reducer,
    customers: Customers.reducer,
    processors: Processors.reducer,
    products: Products.reducer,
    pixels: Pixels.reducer,
    scrubs: Scrubs.reducer,
    rules: Rules.reducer,
    tasks: Tasks.reducer,
    groups: Groups.reducer,
    corporations: Corporations.reducer,
    notifications: notifyReducer,
    rebatesCampaigns: RebatesCampaigns.reducer,
  })

const store = createStore(reducers(history), compose(applyMiddleware(routerMiddleware(history), thunk)))

export default store
