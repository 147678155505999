import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import App from './App'
import * as loginActions from './pages/Login/actions'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import store, { history } from './redux/redux-store'

store.subscribe(() => {
  console.log(store.getState())
})

const ConnectedApp = connect(store => ({ login: store.login }), {
  getMe: loginActions.getMe,
})(({ login, getMe }) => {
  let { token, refreshToken, roles } = login
  if (!token || !refreshToken) {
    const tokenStorage = JSON.parse(localStorage.getItem('tokenStorage'))
    if (tokenStorage) {
      token = tokenStorage.token
      refreshToken = tokenStorage.refreshToken
      roles = tokenStorage.roles

      if (token && refreshToken) {
        useEffect(() => {
          getMe({ token, refreshToken })
        }, [getMe, refreshToken, token])
      }
    }
  }
  return <App token={token} roles={roles} />
})

export default function Routing() {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <BrowserRouter>
          <ConnectedApp />
        </BrowserRouter>
      </ConnectedRouter>
    </Provider>
  )
}
