import { types } from './types'

const initState = {
  list: [],
  logs: {},
  total: 0,
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case types.SET_REBATESCAMPAIGNS_LIST: {
      const { data } = action
      return {
        ...state,
        list: data.items,
        total: data.total,
      }
    }
    case types.REMOVE_REBATESCAMPAIGN: {
      state.list = state.list.filter(item => item.id !== action.data)
      state.total -= 1
      return {
        ...state,
      }
    }
    case types.SET_REBATESCAMPAIGN_STATUS: {
      let campaign = state.list.find(item => item.id == action.data.id)
      const index = state.list.findIndex(item => item.id == action.data.id)
      campaign = { ...campaign, active: action.data.action === 'run_campaign' }
      state.list = [...state.list.slice(0, index), campaign, ...state.list.slice(index + 1)]
      return state
    }
    case types.GET_REBATESCAMPAIGN_STAT: {
      const { data } = action
      return {
        ...state,
        logs: data,
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
