import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Input, Select, Form, Button, InputNumber } from 'antd'
import * as actions from '../actions'
import * as companyActions from '../../Companies/actions'
import { CreateAndBackButtons } from '../../../components'

const AddNewProcessors = ({ fetchProcessorsList, createProcessor, campaigns, fetchCompaniesList, onCancel }) => {
  const [form] = Form.useForm()
  // const [error, setError] = useState('')
  const [crmType, setCrmType] = useState(
    window.localStorage.getItem('crm') && JSON.parse(window.localStorage.getItem('crm')).value
      ? JSON.parse(window.localStorage.getItem('crm')).value
      : 'response',
  )

  useEffect(() => {
    fetchCompaniesList({ page: 1, size: 100, crmType: crmType })
  }, [fetchCompaniesList])

  const onFinishHandler = async values => {
    // if (error === '') {
    await createProcessor({ campaignId: values.campaignId, action: 'import' })
    onCancel()
    fetchProcessorsList({ page: 1, size: 10, crmType: crmType, filters: {} })
    form.resetFields()
    // }
  }

  // const rightLimit = value => {
  //   if (value === null) {
  //     setError('input monthli limit!')
  //   } else (
  //     setError('')
  //   )
  // }

  return (
    <>
      <div className='add-processor_form-page'>
        <Form
          layout='vertical'
          onFinish={onFinishHandler}
          form={form}
          className='addClient_form'
          style={{
            padding: '10px',
          }}
        >
          {/* <Form.Item
            className="addClient_form-item"
            name='limit'
            label="Monthly Limit"
          >
            <InputNumber
              initialValue={1000}
              className="addClient_form-item"
              name='limit'
              label="Monthly Limit"
              style={{ width: '100%' }}
              formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
              onChange={ rightLimit }
            />
          </Form.Item> */}

          <Form.Item
            style={{
              width: '400px',
            }}
            name='campaignId'
            label='Select Company'
            rules={[{ required: true, message: 'Please select company!' }]}
          >
            <Select>
              {campaigns.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <div className='add-new-processor_buttons'>
            <Button
              onClick={() => onCancel()}
              className='addClient_form-item addClient_form-button'
              type='primary'
              style={{
                background: '#1890ff',
                color: '#fff',
                borderRadius: '5px',
                padding: '5px',
                height: '40px',
                width: '100px',
                fontWeight: 'bold',
                marginRight: '5px',
              }}
            >
              <a>
                <i className='zmdi zmdi-arrow-left mr-10'></i>
                Back
              </a>
            </Button>
            <Button
              className='addClient_form-item addClient_form-button'
              type='submit'
              style={{
                background: 'green',
                color: '#fff',
                borderRadius: '5px',
                padding: '5px',
                height: '40px',
                width: '100px',
                fontWeight: 'bold',
                marginRight: '5px',
              }}
              htmlType='submit'
            >
              <i className='zmdi zmdi-plus mr-10' />
              Import
            </Button>
          </div>
        </Form>
      </div>
    </>
  )
}

const mapStateToProps = store => ({
  campaigns: store.companies.list,
})

const mapDispatchToProps = {
  createProcessor: actions.createProcessor,
  fetchProcessorsList: actions.fetchProcessorsList,
  fetchCompaniesList: companyActions.fetchCompaniesList,
}

export default connect(mapStateToProps, mapDispatchToProps)(AddNewProcessors)
