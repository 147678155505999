import React from 'react'
import { connect } from 'react-redux'
import CustomerInfoItem from './CustomerInfoItem'

const CustomerPage = ({ customer }) => {
  customer.transactionDate = new Date(customer.transactionDate * 1000).toDateString()

  return (
    <>
      <div className='customer_main-info'>
        <div className='customer_main-info_section'>
          <h5 className='customer_main-info__title'>
            Sandy Dickerson
            <i className='zmdi zmdi-edit zmdi-hc-lg users-list-action_item'></i>
          </h5>
          <CustomerInfoItem name={'customer id:'} info={customer.externalId} />
          <CustomerInfoItem name={'transaction date:'} info={customer.transactionDate} />
        </div>

        <div className='customer_main-info_section'>
          <h5 className='customer_main-info__title'>Customer Lifetime Value: ${customer.sum}</h5>
          <CustomerInfoItem name={'first name:'} info={customer.billingFirstname} />
          <CustomerInfoItem name={'last name:'} info={customer.billingLastname} />
        </div>

        <div className='customer_main-info_section'>
          <div className='customer_main-info__item customer_main-info__item-question'>
            <div className='customer_type__bold'>
              <i className='zmdi zmdi-info-outline'></i>
              Did customer submit a chargeback?
            </div>
            <input type='checkbox' />
          </div>
          <div className='customer_main-info__item customer_main-info__item-question'>
            <div className='customer_type__bold'>
              <i className='zmdi zmdi-info-outline'></i>
              Test customer?
            </div>
            <input type='checkbox' />
          </div>
          <div className='customer_main-info__item customer_main-info__item-question'>
            <div className='customer_type__bold'>
              <i className='zmdi zmdi-info-outline'></i>
              Cancelled customer?
            </div>
            <input type='checkbox' />
          </div>
          <div className='customer_main-info__item customer_main-info__item-question'>
            <div className='customer_type__bold'>
              <i className='zmdi zmdi-info-outline'></i>
              Blacklisted customer?
            </div>
            <input type='checkbox' />
          </div>
        </div>
      </div>

      <div className='customer_details'>
        <div className='customer_details_item customer_details_item__one'>
          <h5 className='customer_main-info__title'>Customer Information:</h5>
          <CustomerInfoItem name={'ip address:'} info={customer.billingFirstname} />
          <CustomerInfoItem name={'SITE:'} info={customer.externalSiteId} />
        </div>

        <div className='customer_details_item customer_details_item__two'>
          <h5 className='customer_main-info__title'>Contact Information:</h5>
          <CustomerInfoItem name={'FIRST NAME:'} info={customer.billingFirstname} />
          <CustomerInfoItem name={'LAST NAME:'} info={customer.billingLastname} />
          <CustomerInfoItem name={'EMAIL:'} info={customer.email} />
          <CustomerInfoItem name={'PHONE:'} info={customer.phone} />
        </div>

        <div className='customer_details_item customer_details_item__three'>
          <h5 className='customer_main-info__title'>Billeng Address:</h5>
          <CustomerInfoItem name={'CITY:'} info={customer.billingCity} />
          <CustomerInfoItem name={'STATE:'} info={customer.billingState} />
          <CustomerInfoItem name={'COUNTRY:'} info={customer.billingCountry} />
          <CustomerInfoItem name={'ADDRES:'} info={customer.billingAddress} />
          <CustomerInfoItem name={'ZIP CODE:'} info={customer.shippingZip} />
        </div>

        <div className='customer_details_item customer_details_item__four'>
          <h5 className='customer_main-info__title'>Shipping Address:</h5>
          <CustomerInfoItem name={'CITY:'} info={customer.shippingCity} />
          <CustomerInfoItem name={'STATE:'} info={customer.shippingState} />
          <CustomerInfoItem name={'COUNTRY:'} info={customer.shippingCountry} />
          <CustomerInfoItem name={'ADDRES:'} info={customer.shippingAddress} />
          <CustomerInfoItem name={'ZIP CODE:'} info={customer.shippingZip} />
        </div>

        <div className='customer_details_item customer_details_item__five'>
          <h5 className='customer_main-info__title'>Payment Information:</h5>
          <CustomerInfoItem name={'PAYMENT TYPE:'} info={'none'} />
          <CustomerInfoItem name={'NAME ON CARD:'} info={'none'} />
          <CustomerInfoItem name={'CARD TYPE:'} info={'none'} />
          <CustomerInfoItem name={'CC NUMBER:'} info={'none'} />
          <CustomerInfoItem name={'EXP MONTH:'} info={'none'} />
          <CustomerInfoItem name={'EXP YEAR:'} info={'none'} />
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  customer: state.customers.customer,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerPage)
