import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Drawer,
  Input,
  Form,
  InputNumber,
  Select,
  Modal,
  Col,
  Row,
  Checkbox,
  Table,
  Popconfirm,
  DatePicker,
  TimePicker,
} from 'antd'
import * as actions from '../actions'
import * as compaignsActions from '../../Companies/actions'
import * as sitesActions from '../../Sites/actions'
import * as productsActions from '../../Products/actions'
import * as processorsActions from '../../Processors/actions'
import { DeleteFilled, EditFilled } from '@ant-design/icons'

const EditAttempt = ({ attempt, editAttempt }) => {
  const [isVisible, setVisible] = useState(false)
  const [form] = Form.useForm()

  const onFinishHandler = async values => {
    setVisible(false)
    attempt.price = values.price
    attempt.delay = values.delay
    editAttempt()
    form.resetFields()
  }

  return (
    <>
      <EditFilled
        onClick={() => {
          setVisible(true)
          form.resetFields()
        }}
      />
      <Modal
        title='Edit Attempt'
        className={'rebatesCampaign_popup'}
        onClose={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        visible={isVisible}
        footer={[
          <Button key='back' onClick={() => setVisible(false)}>
            Cancel
          </Button>,
          <Button key='submit' type='primary' onClick={() => form.submit()}>
            Edit
          </Button>,
        ]}
      >
        <Form
          hideRequiredMark
          onFinish={onFinishHandler}
          form={form}
          labelCol={{ span: 24, offset: 0 }}
          wrapperCol={{ span: 24, offset: 0 }}
        >
          <Row gutter={8}>
            <Col sm={12}>
              <Form.Item
                name='price'
                label='Price'
                rules={[{ required: true, message: 'Please  input Price!' }]}
                initialValue={attempt.price}
              >
                <InputNumber min={0} precision={2} />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                name='delay'
                label='Delay (in minutes) after main charge'
                rules={[{ required: true, message: 'Please input Delay!' }]}
                initialValue={attempt.delay}
              >
                <InputNumber min={0} precision={0} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

export default EditAttempt
