import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Button, Drawer, Input, Form, InputNumber, Select } from 'antd'
import * as actions from '../actions'
import TextArea from 'antd/es/input/TextArea'
import * as sitesActions from '../../Sites/actions'

const UpdatePixel = ({ record, setPixelsList, updatePixel, sites, setSitesListForSelect }) => {
  const [isVisible, setVisible] = useState(false)
  const [form] = Form.useForm()
  const [crmType, setCrmType] = useState(
    window.localStorage.getItem('crm') && JSON.parse(window.localStorage.getItem('crm')).value
      ? JSON.parse(window.localStorage.getItem('crm')).value
      : 'response',
  )

  const onFinishHandler = async values => {
    await updatePixel({
      campaignId: +record.campaign_id,
      pixelId: +record.id,
      step: +values.step,
      link: values.link,
      affId: values.affId,
      productId: +values.productId,
      siteId: +values.siteId,
    })
    setPixelsList({ crmType: crmType })
    setVisible(false)
    form.resetFields()
  }
  useEffect(() => {
    isVisible && setSitesListForSelect({ page: 1, size: 1000, filters: { campaignId: +record.campaign_id } })
  }, [record.campaign_id, isVisible])

  return (
    <>
      <i
        className='zmdi zmdi-edit zmdi-hc-2x zmdi-hc-lg txt-warning users-list-action_item'
        title='Edit'
        type='primary'
        onClick={() => {
          form.resetFields()
          setVisible(true)
        }}
      />
      <Drawer
        title='Update Pixel'
        className={'add_pixel_popup'}
        onClose={() => setVisible(false)}
        visible={isVisible}
        width={350}
        destroyOnClose={true}
      >
        <Form layout='vertical' hideRequiredMark onFinish={onFinishHandler} form={form}>
          Templates:
          <br />
          [[TRANSACTION_ID]] [[CUSTOMER_ID]] [[AFF_ID]] [[CLICK_ID]] [[S1]] [[S2]] [[S3]] [[S4]] [[S5]] [[IP]]
          [[AMOUNT]]
          <Form.Item
            initialValue={record.link}
            name='link'
            label='Link'
            rules={[{ required: true, message: 'Please input Pixel Link!' }]}
          >
            <TextArea />
          </Form.Item>
          <Form.Item
            initialValue={record.step}
            name='step'
            label='Step'
            rules={[{ required: true, message: 'Please input Pixel Step!' }]}
          >
            <InputNumber min={0} max={10} />
          </Form.Item>
          <Form.Item
            initialValue={record.site_id}
            name='siteId'
            label='Site ID'
            rules={[{ required: true, message: 'Please Select Pixel Site ID!' }]}
          >
            <Select className='task_filter_item' placeholder='select site' showSearch={true}>
              {sites.map((site, key) => {
                return (
                  <Select.Option key={key} value={site.externalId}>
                    {site.externalId} - {site.name}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
          <Form.Item initialValue={record.product_id} name='productId' label='Product ID'>
            <Input />
          </Form.Item>
          <Form.Item initialValue={record.aff_id} name='affId' label='Affiliate ID'>
            <Input />
          </Form.Item>
          <div
            style={{
              position: 'absolute',
              left: 0,
              display: 'flex',
              justifyContent: 'space-between',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
            }}
          >
            <Button onClick={() => setVisible(false)}>Close</Button>
            <Button type='primary' htmlType='submit' className='add-new-site_button'>
              Update
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  )
}
const mapStateToProps = store => ({
  sites: store.sites.sitesForSelect,
})
const mapDispatchToProps = {
  setPixelsList: actions.setPixelsList,
  updatePixel: actions.updatePixel,
  setSitesListForSelect: sitesActions.fetchSitesListForSelect,
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePixel)
