import { types } from './types'

const initState = {
  listCorp: [],
  listCorpSelect: [],
  total: 0,
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case types.SET_GROUP_LIST: {
      const { data } = action
      return {
        ...state,
        listCorp: data.items,
        total: data.total,
      }
    }
    case types.SET_GROUP_LIST_SELECT: {
      const { data } = action
      return {
        ...state,
        listCorpSelect: data.items,
        total: data.total,
      }
    }
    case types.REMOVE_GROUP: {
      state.listCorp = state.listCorp.filter(item => item.id !== action.data)
      state.total -= 1
      return {
        ...state,
      }
    }
    case types.PAUSE_CORP: {
      const { corpId, pause } = action
      console.log('pause: ', pause)
      let stateCopy = { ...state }
      stateCopy.listCorp = stateCopy.listCorp.map(item => {
        if (item.id == corpId) {
          console.log('item: ', item)
          item.pause = pause
        }
        return item
      })
      return {
        ...stateCopy,
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
