import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, Drawer, Select, Form } from 'antd'
import * as actions from '../actions'
import * as companyActions from '../../Companies/actions'

const UpdateSites = ({ fetchSitesList, createSite, campaigns, fetchCompaniesList }) => {
  const [isVisible, setVisible] = useState(false)
  const [form] = Form.useForm()
  const [crmType, setCrmType] = useState(
    window.localStorage.getItem('crm') && JSON.parse(window.localStorage.getItem('crm')).value
      ? JSON.parse(window.localStorage.getItem('crm')).value
      : 'response',
  )

  useEffect(() => {
    fetchCompaniesList({ page: 1, size: 100, crmType: crmType })
  }, [fetchCompaniesList])

  const onFinishHandler = async values => {
    await createSite({ campaignId: values.campaignId, action: 'update' })
    fetchSitesList({ crmType: crmType })
    setVisible(false)
    form.resetFields()
  }

  return (
    <>
      <Button
        type='primary'
        onClick={() => {
          setVisible(true)
        }}
        className='add-new-site_button'
      >
        Update Sites
      </Button>
      <Drawer
        title='Update Sites for'
        className={'add_product_popup'}
        onClose={() => setVisible(false)}
        visible={isVisible}
        forceRender
      >
        <Form layout='vertical' hideRequiredMark onFinish={onFinishHandler} form={form}>
          <Form.Item
            name='campaignId'
            label='Select Company'
            rules={[{ required: true, message: 'Please select company!' }]}
          >
            <Select>
              {campaigns.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <div
            style={{
              position: 'absolute',
              left: 0,
              display: 'flex',
              justifyContent: 'space-between',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
            }}
          >
            <Button onClick={() => setVisible(false)}>Close</Button>
            <Button type='primary' htmlType='submit' className='add-new-site_button'>
              Update
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  )
}

const mapStateToProps = store => ({
  campaigns: store.companies.list,
})

const mapDispatchToProps = {
  fetchSitesList: actions.fetchSitesList,
  fetchCompaniesList: companyActions.fetchCompaniesList,
  createSite: actions.createSite,
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateSites)
