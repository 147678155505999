import axios from '../../utils/axios'
import settings from '../../settings'
import { types } from './types'
import { guardFromErrors, extractErrorInfo } from '../../utils/graphqlHelper'
import { errorNotification, successNotification } from '../../utils/notificationHelper'

const SET_PRODUCTS_LIST = `
query productsListPage($page: Int, $size: Int, $filters: ProductsFilters!, $action: String, $crmType: String) {
  productsListPage(page: $page, size: $size, filters: $filters, action: $action, crmType: $crmType) {
    items {
      id
      campaignId
      name
      externalId
      externalSiteId
      type
      amount
    }
    total
  }
}
`

const DOWNLOAD_PRODUCT = `
  mutation downloadProduct ($campaignId: ID!, $action: String!) {
    downloadProduct(campaignId: $campaignId, action: $action) {
      externalId
      name
      type
      amount
    }
  }
`

const REMOVE_PRODUCT = `
  mutation removeProduct($externalId: ID!) {
    removeProduct(externalId: $externalId)
  }
`

const UPDATE_PRODUCT = `
  mutation updateProduct($externalId: ID!, $name: String, $type: String, $amount: String) {
    updateProduct(externalId: $externalId, name: $name, type: $type, amount: $amount)
  }
`

//setProductsList
export const setProductsList =
  ({ page, size, filters = {}, action, crmType }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })

      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: SET_PRODUCTS_LIST,
        variables: { page, size, filters, action, crmType },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { productsListPage } = data
      if (!productsListPage) {
        throw new Error("Can't get products!")
      }

      dispatch({
        type: types.SET_PRODUCTS_LIST,
        data: productsListPage,
      })

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      dispatch(errorNotification(errorMessage))
    }
  }

//downloadProduct
export const downloadProduct = formData => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })
    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: DOWNLOAD_PRODUCT,
      variables: { ...formData },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { downloadProduct } = data
    if (!downloadProduct) {
      throw new Error('The operation was not successful!')
    }

    dispatch(successNotification('Operation Completed Successfully!'))

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
  }
}

//remove Product
export const removeProduct = externalId => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: REMOVE_PRODUCT,
      variables: { externalId },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { removeProduct } = data
    if (!removeProduct) {
      throw new Error("Can't remove Product")
    }

    dispatch({
      type: types.REMOVE_PRODUCT,
      data: externalId,
    })

    dispatch(successNotification('Remove Product successful'))

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
  }
}

//update Product
export const updateProduct = args => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: UPDATE_PRODUCT,
      variables: {
        externalId: args.externalId,
        name: args.name,
        type: args.type,
        amount: args.amount,
      },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateProduct } = data
    if (!updateProduct) {
      throw new Error("Can't update Product!")
    }

    dispatch(successNotification('Product update successful!'))

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
  }
}
