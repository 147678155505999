import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, Drawer, Input, Form, Select, InputNumber, Popconfirm } from 'antd'
import * as actions from '../actions'
import * as compaignsActions from '../../Companies/actions'

const UpdateGroup = ({
  record,
  fetchCompaniesList,
  campaigns,
  updateGroup,
  setGroupList,
  groupsListSelect,
  setGroupListSelect,
}) => {
  const [isVisible, setVisible] = useState(false)
  const [form] = Form.useForm()
  const [crmType, setCrmType] = useState(
    window.localStorage.getItem('crm') && JSON.parse(window.localStorage.getItem('crm')).value
      ? JSON.parse(window.localStorage.getItem('crm')).value
      : 'response',
  )
  const [groups, SetGroups] = useState(
    record && record.groupToGroups
      ? record.groupToGroups.map((group, index) => {
          return {
            ...group,
            order: index,
          }
        })
      : [],
  )
  const [groupsCount, SetGroupsCount] = useState(record && record.groupToGroups ? record.groupToGroups.length : 0)
  const [errorSumMessage, setErrorSumMessage] = useState('')
  useEffect(() => {
    fetchCompaniesList({ page: 1, size: 100, crmType: crmType })
  }, [fetchCompaniesList])
  useEffect(() => {
    setGroupListSelect({
      page: 1,
      size: 10000,
      crmType: crmType,
      filters: { campaignId: record.campaignId, type: 'corp' },
    })
  }, [isVisible])

  const changeGroups = () => {}

  const doConfirm = group => {
    SetGroups(groups.filter(fGroup => fGroup !== group))
    setErrorSumMessage('')
  }

  const onFinishHandler = async values => {
    setErrorSumMessage('')
    let percent = 0
    groups.map(group => {
      percent += +group.percent
    })
    if (percent !== 100 && groups && groups.length > 0) {
      setErrorSumMessage('Total percent must be 100')
      return false
    }

    await updateGroup({
      groupId: +values.groupId,
      campaignId: +values.campaignId,
      transMax: values.transMax,
      name: values.name,
      groupToGroups:
        groups && groups.length > 0
          ? groups.map(group => {
              return {
                groupId: values.groupId,
                percent: group.percent,
                spareGroupId: group.spareGroupId,
              }
            })
          : [],
    })
    //await setGroupList({ page: 1, size: 10,crmType:crmType, filters: { type: 'corp' } })
    //setVisible(false)
    //form.resetFields()
  }

  return (
    <>
      <i
        className='zmdi zmdi-edit zmdi-hc-2x zmdi-hc-lg txt-warning users-list-action_item'
        title='Edit'
        type='primary'
        onClick={() => {
          setVisible(true)
        }}
      />
      <Drawer
        title='Update Corporation'
        className={'add_product_popup'}
        onClose={() => setVisible(false)}
        visible={isVisible}
        forceRender
      >
        <Form
          layout='vertical'
          hideRequiredMark
          onFinish={onFinishHandler}
          form={form}
          style={{ paddingBottom: '50px' }}
        >
          <Form.Item
            initialValue={record.campaignId}
            name='campaignId'
            label='Select a campaign'
            rules={[{ required: true, message: 'Select campaign!' }]}
          >
            <Select>
              {campaigns.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            initialValue={record.name}
            name='name'
            label='Input Corporation Name'
            rules={[{ required: true, message: 'Input Corporation Name!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            initialValue={record.transMax}
            name='transMax'
            label='Input transMax Value'
            rules={[
              {
                required: true,
                message: 'Input transMax Value!',
                type: 'number',
              },
            ]}
          >
            <InputNumber />
          </Form.Item>

          <Form.Item initialValue={record.id} name='groupId' label='Corporation ID'>
            <Input disabled />
          </Form.Item>

          <h5>Cascade</h5>
          <Button
            onClick={() => {
              SetGroups([...groups, { percent: 0, spareGroupId: null, order: +groupsCount + +1 }])
              SetGroupsCount(+groupsCount + +1)
            }}
          >
            Add
          </Button>
          <div className={'spareGroups'}>
            {groups.map((group, index) => {
              return (
                <div className={'spareGroupsRow'} key={'spareGroup' + group.order}>
                  <Form.Item
                    initialValue={group.spareGroupId}
                    name={'corpId' + group.order}
                    label='Select a corporation'
                    rules={[{ required: true }]}
                  >
                    <Select onChange={v => (group.spareGroupId = v)}>
                      {groupsListSelect.map(item => {
                        if (item.id != record.id)
                          return (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          )
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    initialValue={group.percent}
                    name={'corpPercent' + group.order}
                    label='Percent'
                    rules={[
                      {
                        required: true,
                        message: 'max 100%',
                        min: 1,
                        max: 100,
                        type: 'number',
                      },
                    ]}
                  >
                    <InputNumber onChange={v => (group.percent = v)} />
                  </Form.Item>
                  <Popconfirm
                    title='Are you sure delete this Product?'
                    onConfirm={e => doConfirm(group)}
                    onCancel={e => e?.stopPropagation()}
                    okText='Yes'
                    cancelText='No'
                  >
                    <i className='zmdi zmdi-delete zmdi-hc-2x txt-danger users-list-action_item' title='Delete'></i>
                  </Popconfirm>
                </div>
              )
            })}
          </div>
          <div style={{ color: 'red' }}>{errorSumMessage}</div>

          <div
            style={{
              position: 'absolute',
              left: 0,
              display: 'flex',
              justifyContent: 'space-between',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
            }}
          >
            <Button onClick={() => setVisible(false)}>Close</Button>
            <Button type='primary' htmlType='submit' className='add-new-site_button'>
              Update
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  )
}

const mapStateToProps = store => ({
  campaigns: store.companies.list,
  groupsListSelect: store.corporations.listCorpSelect,
})

const mapDispatchToProps = {
  fetchCompaniesList: compaignsActions.fetchCompaniesList,
  updateGroup: actions.updateGroup,
  setGroupList: actions.setGroupList,
  setGroupListSelect: actions.setGroupListSelect,
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateGroup)
