import React, { useEffect, useState } from 'react'
import { Table, Popconfirm, Button, Select } from 'antd'
import { Main } from '../../../components'
import * as actions from '../actions'
import { connect } from 'react-redux'
import { UpdatePixel } from '../components'
import CreatePixel from '../components/CreatePixel'
import PixelStat from '../components/PixelStat'
import * as sitesActions from '../../Sites/actions'

const PixelsList = ({ setPixelsList, total, pixelsList, removePixel, compaignId, compaigns, sites, setSitesList }) => {
  const [pageSize, setPageSize] = useState(10)
  const [pageNumber, setPageNumber] = useState(1)
  const [crmType, setCrmType] = useState(
    window.localStorage.getItem('crm') && JSON.parse(window.localStorage.getItem('crm')).value
      ? JSON.parse(window.localStorage.getItem('crm')).value
      : 'response',
  )
  const [siteId, setSiteId] = useState(
    window.localStorage.getItem('siteId') && JSON.parse(window.localStorage.getItem('siteId')).value
      ? JSON.parse(window.localStorage.getItem('siteId')).value
      : 'all',
  )

  useEffect(() => {
    const campaignItem = JSON.parse(window.localStorage.getItem('campaign'))
    if (campaignItem !== '' && campaignItem) {
      if (siteId !== 'all') {
        setPixelsList({
          page: pageNumber,
          size: pageSize,
          filters: { campaign_id: +campaignItem.id, site_id: +siteId },
        })
      } else {
        setPixelsList({
          page: pageNumber,
          size: pageSize,
          filters: { campaign_id: +campaignItem.id },
        })
      }
      setSitesList({ page: 1, size: 1000, filters: { campaignId: +campaignItem.id } })
    } else {
      if (siteId !== 'all') {
        setPixelsList({
          page: pageNumber,
          size: pageSize,
          filters: { site_id: siteId },
        })
      } else {
        setPixelsList({
          page: pageNumber,
          size: pageSize,
          filters: {},
        })
      }
      setSitesList({ page: 1, size: 1000, site_id: siteId })
    }
  }, [pageSize, pageNumber, compaignId, siteId])

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'SiteID',
      dataIndex: 'site_id',
      key: 'site_id',
    },
    {
      title: 'AffID',
      dataIndex: 'aff_id',
      key: 'aff_id',
    },
    {
      title: 'ProductID',
      dataIndex: 'product_id',
      key: 'product_id',
    },
    {
      title: 'Step',
      dataIndex: 'step',
      key: 'step',
    },
    {
      title: 'Actions',
      dataIndex: '',
      key: '',
      render: record => (
        <>
          <UpdatePixel record={record} />
          <PixelStat record={record} />
          <Popconfirm
            title='Are you sure delete this Pixel?'
            onConfirm={e => doConfirm(record.id, e)}
            onCancel={e => e?.stopPropagation()}
            okText='Yes'
            cancelText='No'
          >
            <i className='zmdi zmdi-delete zmdi-hc-2x txt-danger users-list-action_item' href='/#' title='Delete'></i>
          </Popconfirm>
        </>
      ),
    },
  ]

  const doConfirm = (pixelId, e) => {
    e && e.stopPropagation()
    removePixel(pixelId)
    if (compaignId !== '') {
      setPixelsList({
        page: pageNumber,
        size: pageSize,
        filters: { campaign_id: +compaignId },
      })
    } else {
      setPixelsList({ page: pageNumber, size: pageSize, filters: {} })
    }
  }

  const changePaginataion = data => {
    const { current, pageSize: paginationSize } = data
    setPageSize(paginationSize)
    setPageNumber(current)
  }

  return (
    <Main>
      <h4 className='txt-dart'>Pixels</h4>
      <div>
        <CreatePixel />
        <Select
          className='task_filter_item'
          onSelect={value => setSiteId(value)}
          placeholder='select site'
          showSearch={true}
        >
          <Select.Option value='all'>All sites</Select.Option>
          {sites.map((site, key) => {
            return (
              <Select.Option key={key} value={site.externalId}>
                {site.externalId} - {site.name}
              </Select.Option>
            )
          })}
        </Select>
      </div>
      <Table
        columns={columns}
        dataSource={pixelsList}
        bordered
        onChange={changePaginataion}
        rowKey={record => record.id}
        scroll={{ x: 768, y: 10000 }}
        pagination={{
          showSizeChanger: true,
          pageSize,
          current: pageNumber,
          total,
        }}
      />
    </Main>
  )
}

const mapStateToProps = store => ({
  pixelsList: store.pixels.list,
  total: store.pixels.total,
  sites: store.sites.sitesForSelect,
  compaigns: store.companies.list,
  compaignId: store.companies.compaign,
})

const mapDispatchToProps = {
  setPixelsList: actions.setPixelsList,
  removePixel: actions.removePixel,
  setSitesList: sitesActions.fetchSitesList,
}

export default connect(mapStateToProps, mapDispatchToProps)(PixelsList)
