/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react'
import { Table, Popconfirm, Modal } from 'antd'
import { Main } from '../../../components'
import CustomerInfo from '../components/CustomerInfo'
import AddCsv from '../components/AddCsv'
import SiteFilter from '../components/SiteFilter'
import CompanyFilter from '../components/СompanyFilter'
import CustomerPage from '../components/CustomerPage'
import * as actions from '../actions'
import * as companyActions from '../../Companies/actions'
import { connect } from 'react-redux'
import { errorNotification, successNotification } from '../../../utils/notificationHelper'

const CustomersList = ({
  customersList,
  fetchCustomersList,
  total,
  removeCustomer,
  downloadCustomers,
  getCustomer,
  companiesForOptions,
  fetchCompaniesForOptions,
  compaignId,
  errorDownloadCustomers,
}) => {
  const [pageSize, setPageSize] = useState(10)
  const [pageNumber, setPageNumber] = useState(1)
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (compaignId !== '') {
      fetchCustomersList({
        page: pageNumber,
        size: pageSize,
        filters: { campaignId: +compaignId },
      })
    } else {
      fetchCustomersList({ page: pageNumber, size: pageSize, filters: {} })
    }
    fetchCompaniesForOptions()
  }, [fetchCustomersList, pageSize, pageNumber, compaignId])

  customersList.map(item => {
    item.transactionDate = new Date(item.transactionDate * 1000).toDateString()
    item.sum = Math.floor(item.sum * 100) / 100
  })

  const columns = [
    {
      title: 'Customer ID',
      dataIndex: 'externalId',
      key: 'externalId',
    },
    {
      title: 'Site ID',
      dataIndex: 'externalSiteId',
      key: 'externalSiteId',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Campaign Name',
      dataIndex: 'campaignId',
      key: 'campaignId',
      render: record => {
        let name = ''
        companiesForOptions.map(item => {
          if (item.id == record) {
            name = item.name
          }
        })
        return (
          <div>
            {name} ({record})
          </div>
        )
      },
    },
    {
      title: 'Amount',
      dataIndex: '',
      key: '',
      render: record => {
        if (record.sum) {
          return <div>{record.sum}</div>
        } else {
          return <div>{record.amount}</div>
        }
      },
    },
    {
      title: 'Actions',
      dataIndex: '',
      key: 'x',
      render: record => (
        <>
          {/* <CustomerInfo record={ record }/> */}

          <Popconfirm
            title='Are you sure delete this customer?'
            onConfirm={e => removeUserHandler(record.externalId)}
            onCancel={e => e?.stopPropagation()}
            okText='Yes'
            cancelText='No'
          >
            <i className='zmdi zmdi-delete zmdi-hc-2x txt-danger users-list-action_item' href='/#' title='Delete'></i>
          </Popconfirm>

          <i
            className='zmdi zmdi-search zmdi-hc-2x users-list-action_item'
            color='blue'
            title='show more'
            type='primary'
            onClick={() => showModal(record)}
          />
        </>
      ),
    },
  ]

  const removeUserHandler = async customerID => {
    await removeCustomer(customerID)
    if (compaignId !== '') {
      fetchCustomersList({
        page: pageNumber,
        size: pageSize,
        filters: { campaignId: +compaignId },
      })
    } else {
      fetchCustomersList({ page: pageNumber, size: pageSize, filters: {} })
    }
  }

  const showModal = record => {
    getCustomer(record.id)
    setVisible(true)
  }

  const handleOk = () => {
    setVisible(false)
  }

  const handleCancel = () => {
    setVisible(false)
  }

  const changePaginataion = data => {
    const { current, pageSize: paginationSize } = data
    setPageSize(paginationSize)
    setPageNumber(current)
  }

  return (
    <Main>
      <h4 className='txt-dark'>Customers list</h4>
      <Table
        columns={columns}
        dataSource={customersList}
        width='500'
        bordered
        onChange={changePaginataion}
        rowKey={record => record.id}
        pagination={{
          showSizeChanger: true,
          pageSize,
          current: pageNumber,
          total,
        }}
      />
      <Modal
        className='customer_modal'
        title='Customer Detalis'
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width='50%'
      >
        <CustomerPage />
      </Modal>
    </Main>
  )
}

const mapStateToProps = store => ({
  customersList: store.customers.list,
  companiesForOptions: store.companies.companiesForOptions,
  total: store.customers.total,
  compaignId: store.companies.compaignId,
})

const mapDispatchToProps = {
  fetchCustomersList: actions.fetchCustomersList,
  removeCustomer: actions.removeCustomer,
  downloadCustomers: actions.downloadCustomers,
  fetchCompaniesForOptions: companyActions.fetchCompaniesForOptions,
  getCustomer: actions.getCustomer,
  errorDownloadCustomers: actions.errorDownloadCustomers,
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomersList)
