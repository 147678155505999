/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Col, Row, Form, Drawer, Button, Checkbox, Input } from 'antd'
import * as actions from '../actions'
const { Search } = Input

const AddProduct = ({ products, ruleProducts, onAddProduct = () => {} }) => {
  const [form] = Form.useForm()
  const [isVisible, setVisible] = useState(false)
  const [search, setSearch] = useState(false)
  const [searchProduct, setSearchProduct] = useState([])

  console.log('ruleProducts: ', ruleProducts)

  useEffect(() => {
    setSearchProduct(searchProduct)
  }, [searchProduct.length])

  const onFinishHendler = async values => {
    const productsForShare = products.filter(item => values.externalProductId.includes(item.externalId))
    onAddProduct(productsForShare)
    form.resetFields()
    setVisible(false)
  }

  const onSearch = value => {
    products.map(product => {
      if (product.externalId === value) {
        searchProduct.push({
          id: product.externalId,
          name: product.name,
        })
        setSearch(true)
      }
    })
    if (value === '') {
      setSearch(false)
    }
  }

  return (
    <div>
      <Button type='primary' className='add-new-site_button' onClick={() => setVisible(true)}>
        Add a Product
      </Button>

      <Drawer title='Add Product' width='400' onClose={() => setVisible(false)} visible={isVisible} forceRender>
        <Search placeholder='input search text' onSearch={onSearch} style={{ width: 200 }} />
        <Form layout='vertical' hideRequiredMark onFinish={onFinishHendler} form={form}>
          {search ? (
            <Form.Item
              name='externalProductId'
              label='Products'
              rules={[{ required: true, message: 'Select product' }]}
            >
              <Checkbox.Group>
                <Row>
                  <Col span={20}>
                    {searchProduct.map((product, key) => (
                      <Checkbox
                        key={key}
                        value={product.id}
                        style={{
                          lineHeight: '32px',
                        }}
                      >
                        {product.id} ({product.name})
                      </Checkbox>
                    ))}
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          ) : (
            <Form.Item
              name='externalProductId'
              label='Products'
              rules={[{ required: true, message: 'Select product' }]}
            >
              <Checkbox.Group>
                <Row>
                  {products.map((product, key) => {
                    if (ruleProducts) {
                      let ruleProductsIds = ruleProducts.map(product => {
                        return product.exProductId
                      })
                      let findProduct = product.externalId
                      let num = ruleProductsIds.find(el => +el === +findProduct)
                      if (!num) {
                        return (
                          <Col span={20} key={key}>
                            <Checkbox
                              value={product.externalId}
                              style={{
                                lineHeight: '32px',
                              }}
                            >
                              {product.externalId} - {product.name}
                            </Checkbox>
                          </Col>
                        )
                      }
                    }
                  })}
                </Row>
              </Checkbox.Group>
            </Form.Item>
          )}

          <div
            style={{
              position: 'absolute',
              left: 0,
              display: 'flex',
              justifyContent: 'space-between',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
            }}
          >
            <Button onClick={() => setVisible(false)}>Close</Button>
            <Button type='primary' htmlType='submit' className='add-new-site_button'>
              Add
            </Button>
          </div>
        </Form>
      </Drawer>
    </div>
  )
}

const mapStateToProps = store => ({
  products: store.products.list,
})

const mapDispatchToPtops = {
  addRuleProduct: actions.addRuleProduct,
  deleteRuleProduct: actions.deleteRuleProduct,
  setRuleProductsList: actions.setRuleProductsList,
}

export default connect(mapStateToProps, mapDispatchToPtops)(AddProduct)
