import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Table, Modal, Switch, Button } from 'antd'
import { CheckCircleTwoTone, CloseSquareTwoTone } from '@ant-design/icons'
import * as actions from '../actions'
import { Main } from '../../../components'
import ClientInfo from '../components/ClientInfo'
import AddClient from '../components/AddClient'

const size = 10

const ClientsList = ({ getClientsList, clientsTotal, clientsList, removeUser, setActiveUser }) => {
  const [pageNo, setPageNo] = useState(1)
  const [active, setActive] = useState(1)
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    getClientsList({ page: pageNo, size })
  }, [pageNo, active])

  const paginationHandler = page => {
    getClientsList({ page, size })
    setPageNo(page)
  }

  // remove user
  // const removeUserHandler = async (userId, e) => {
  //   e && e.stopPropagation()
  //   removeUser(userId)
  // }

  const checkActive = async (record, value) => {
    setActiveUser({ userId: record.id, active: value })
    setActive(!active)
  }

  const columns = [
    {
      title: 'id',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'active',
      dataIndex: 'active',
      key: 'active',
      render: record => {
        if (record) {
          return (
            <>
              <CheckCircleTwoTone twoToneColor='#52c41a' />
            </>
          )
        } else {
          return (
            <>
              <CloseSquareTwoTone />
            </>
          )
        }
      },
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Actions',
      dataIndex: '',
      key: 'x',
      render: record => {
        let checkUser
        if (record.active) {
          checkUser = <Switch checked record={record} onChange={value => checkActive(record, value)} />
        } else {
          checkUser = <Switch record={record} onChange={value => checkActive(record, value)} />
        }
        return (
          <>
            <ClientInfo record={record} size={10} page />

            {/* <Popconfirm
              title="Are you sure delete this user?"
              onConfirm={(e) => removeUserHandler(record.id, e)}
              onCancel={(e) => e?.stopPropagation()}
              okText="Yes"
              cancelText="No"
            >
              <i
                className="zmdi zmdi-delete zmdi-hc-2x txt-danger users-list-action_item"
                href="/#"
                title="Delete"
              ></i>
            </Popconfirm> */}
            {checkUser}
          </>
        )
      },
    },
  ]

  const handleCancel = async sum => {
    setVisible(false)
    await getClientsList({ page: pageNo, size })
  }

  const showModal = () => {
    setVisible(true)
  }

  return (
    <Main>
      <h4 className='txt-dark'>Clients list</h4>
      <Button type='primary' onClick={showModal} className='add-new-site_button'>
        Add User
      </Button>
      <Modal
        className='customer_modal'
        title='ADD User'
        visible={visible}
        footer={null}
        onCancel={handleCancel}
        width='50%'
      >
        <AddClient onCancel={handleCancel} />
      </Modal>

      <Table
        columns={columns}
        dataSource={clientsList}
        bordered
        rowKey={record => record.id}
        pagination={{
          onChange: paginationHandler,
          current: pageNo,
          total: clientsTotal,
          pageSize: size,
        }}
      />
    </Main>
  )
}

const mapStateToProps = store => ({
  clientsList: store.clients.list,
  clientsTotal: store.clients.total,
  clientInfo: store.clientInfo,
})

const mapDispatchToProps = {
  getClientsList: actions.getClientsList,
  removeUser: actions.removeUser,
  getClientList: actions.getClientList,
  setActiveUser: actions.setActiveUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientsList)
