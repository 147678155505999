import React, { useEffect } from 'react'
import 'antd/dist/antd.css'
import { Select } from 'antd'
import { connect } from 'react-redux'
import * as clientsActions from '../../pages/Clients/actions'
import * as compaignsActions from '../../pages/Companies/actions'

const HeaderSelectUsers = ({ clientsList, getClientsList, setUserId, fetchCompaniesList }) => {
  useEffect(() => {
    getClientsList(1, 100)
  }, [getClientsList])

  const onChange = async value => {
    if (value === 'all') {
      fetchCompaniesList({ page: 1, size: 10, filters: {} })
    } else {
      await setUserId(value)
      fetchCompaniesList({ page: 1, size: 10, filters: { userId: +value } })
    }
  }

  const onSearch = val => {
    // console.log('search:', val)
  }

  const { Option } = Select

  return (
    <>
      <Select
        style={{ width: 200, marginLeft: 20, marginRight: 20 }}
        showSearch
        placeholder='select a user'
        optionFilterProp='children'
        onChange={onChange}
        onSearch={onSearch}
        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        <Option value='all'>All Users</Option>
        {clientsList.map(client => (
          <Option key={client.id} value={client.id}>
            {client.firstName}
          </Option>
        ))}
      </Select>
    </>
  )
}

const mapStateToProps = store => ({
  clientsList: store.clients.list,
})

const mapDispatchToProps = {
  getClientsList: clientsActions.getClientsList,
  setUserId: compaignsActions.setUserId,
  fetchCompaniesList: compaignsActions.fetchCompaniesList,
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderSelectUsers)
