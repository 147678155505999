/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Collapse, Table } from 'antd'
import * as actions from '../actions'

const { Panel } = Collapse

const TaskListInfo = ({
  record,
  createOrUpdateTaskCustomersStat,
  setTaskCustomersStatList,
  taskStat,
  customersStat,
  taskStatistics,
  setTaskCustomersProductsList,
  taskCustomersProductsList,
  customersStatTotal,
}) => {
  const [pageSize, setPageSize] = useState(10)
  const [pageNumber, setPageNumber] = useState(1)

  useEffect(() => {
    taskStat({ taskId: +record.taskInfo.id })
    async function statistics() {
      await createOrUpdateTaskCustomersStat({ taskId: +record.taskInfo.id })
      await setTaskCustomersProductsList({
        page: 1,
        size: 2000,
        filters: { taskId: record.taskInfo.id },
      })
      await setTaskCustomersStatList({
        page: pageNumber,
        size: pageSize,
        filters: { taskId: +record.taskInfo.id },
      })
    }
    statistics()
  }, [record.taskInfo.id, record.date])

  useEffect(() => {
    setTaskCustomersStatList({
      page: pageNumber,
      size: pageSize,
      filters: { taskId: +record.taskInfo.id },
    })
  }, [pageNumber, pageSize])

  const existTask = []
  for (let i = 0; i < 1; i++) {
    existTask.push(taskStatistics)
  }

  const taskColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Success',
      dataIndex: 'successCustom',
      key: 'successCustom',
    },
    {
      title: 'Decline',
      dataIndex: 'decline',
      key: 'decline',
    },
    {
      title: 'Decline Amount',
      dataIndex: 'declineAmount',
      key: 'declineAmount',
    },
    {
      title: 'Success Amount',
      dataIndex: 'successAmount',
      key: 'successAmount',
    },
    {
      title: 'Canceled',
      dataIndex: 'canceled',
      key: 'canceled',
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
    },
  ]

  const taskCustomersColumns = [
    {
      title: 'ID',
      dataIndex: 'externalId',
      key: 'externalId',
      sorter: (a, b) => a.externalId - b.externalId,
    },
    {
      title: 'Products',
      render: (value, row, index) => {
        let existTaskProducts = []
        for (let i = 0; i < taskCustomersProductsList.length; i++) {
          if (row.externalId === taskCustomersProductsList[i].customerId) {
            existTaskProducts.push({
              ...taskCustomersProductsList[i],
            })
          }
        }
        return (
          <Collapse defaultActiveKey={[]}>
            <Panel header='display products data' key='1'>
              <Table columns={customersProductsColumns} dataSource={existTaskProducts} bordered pagination={false} />
            </Panel>
          </Collapse>
        )
      },
    },
    {
      title: 'Log',
      dataIndex: 'log',
      key: 'log',
      render: record => {
        if (record) {
          const stringWithSpaces = record.replace(/2020{*}/g, '\n2020')
          return <div style={{ whiteSpace: 'pre-line' }}>{stringWithSpaces}</div>
        }
      },
    },
  ]

  const customersProductsColumns = [
    {
      title: 'Product ID',
      dataIndex: 'productId',
      key: 'productId',
      sorter: (a, b) => a.productId - b.productId,
    },
    {
      title: 'Current Status',
      dataIndex: 'currentStatus',
      key: 'currentStatus',
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      key: 'reason',
    },
    {
      title: 'Charge Status',
      dataIndex: 'shargeStatus',
      key: 'shargeStatus',
      filters: [
        {
          text: 'Falied',
          value: 'falied',
        },
        {
          text: 'Success',
          value: 'success',
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.shargeStatus.indexOf(value) === 0,
    },
    {
      title: 'Attempt',
      dataIndex: 'attempt',
      key: 'attempt',
    },
    {
      title: 'Cycle Num',
      dataIndex: 'cycleNum',
      key: 'cycleNum',
    },
    {
      title: 'Next Charge Date',
      dataIndex: 'nextChargeDate',
      key: 'nextChargeDate',
      sorter: (a, b) => new Date(a.nextChargeDate) - new Date(b.nextChargeDate),
    },
  ]

  const changePaginataion = data => {
    const { current, pageSize: paginationSize } = data
    setPageSize(paginationSize)
    setPageNumber(current)
  }
  return (
    <div style={{ padding: '2%' }}>
      <h4 className='txt-dart'>Task Info</h4>
      <Table
        className={'task_stat'}
        columns={taskColumns}
        dataSource={existTask}
        rowKey={record => record.id}
        bordered
        pagination={false}
      />
      <div className={'task_stat_mobile'}>
        <b>ID</b>: {existTask[0].id}
        <br />
        <b>Name</b>: {existTask[0].name}
        <br />
        <b>Status</b>: {existTask[0].status}
        <br />
        <b>Success</b>: {existTask[0].successCustom}
        <br />
        <b>Decline</b>: {existTask[0].decline}
        <br />
        <b>Decline Amount</b>: {existTask[0].declineAmount}
        <br />
        <b>Success Amount</b>: {existTask[0].successAmount}
        <br />
        <b>Canceled</b>: {existTask[0].canceled}
        <br />
        <b>Total</b>: {existTask[0].total}
      </div>

      <h4 className='txt-dart' style={{ marginTop: '2%' }}>
        Task Customers
      </h4>
      <Table
        className={'task_stat_customers'}
        columns={taskCustomersColumns}
        dataSource={customersStat}
        bordered
        onChange={changePaginataion}
        pagination={{
          total: customersStatTotal,
          showSizeChanger: true,
          pageSize,
          current: pageNumber,
        }}
      />
    </div>
  )
}

const mapStateToProps = store => ({
  tasks: store.tasks.list,
  taskStatistics: store.tasks.taskStat,
  customersStat: store.tasks.customersStat,
  customersStatTotal: store.tasks.customersStatTotal,
  taskCustomersProductsList: store.tasks.customerProductsStat,
})

const mapDispatchToProps = {
  setTaskCustomersStatList: actions.setTaskCustomersStatList,
  createOrUpdateTaskCustomersStat: actions.createOrUpdateTaskCustomersStat,
  taskStat: actions.taskStat,
  setTaskCustomersProductsList: actions.setTaskCustomersProductsList,
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskListInfo)
