import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Main } from '../../../components'
import { AddNewRule } from '../components'
import { Button, Popconfirm, Table, Modal } from 'antd'
import * as actions from '../actions'
import * as actionsGroups from '../../Corporations/actions'
import UpdateRule from '../components/UpdateRule'

const RulesListPage = props => {
  const [visible, setVisible] = useState(false)
  const [visibleRuleInfo, setVisibleRuleInfo] = useState(false)
  const [ruleIngoRecord, setRuleInfoReocord] = useState([])
  const [pageSize, setPageSize] = useState(10)
  const [pageNumber, setPageNumber] = useState(1)
  const [existGroupName, setExistGroupName] = useState('')
  const [crmType, setCrmType] = useState(
    window.localStorage.getItem('crm') && JSON.parse(window.localStorage.getItem('crm')).value
      ? JSON.parse(window.localStorage.getItem('crm')).value
      : 'response',
  )
  useEffect(() => {
    props.setGroupList({ page: 1, size: 100, crmType: crmType, filters: {} })
  }, [])
  useEffect(() => {
    props.groups.map(group => {
      if (group.id == getParameterByName('groupId')) {
        setExistGroupName(group.name)
      }
    })
    props.setRulesList({
      page: 1,
      size: 100,
      filters: { groupId: +getParameterByName('groupId') },
    })
  }, [props.groups])

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = async sum => {
    setVisible(false)
    setVisibleRuleInfo(false)
  }

  const doConfirm = async (ruleId, e) => {
    props.deleteRule({ ruleId })
  }

  // const pauseRule = async (ruleId, pause) => {
  //   await props.pauseRule({ ruleId, pause })
  // }

  const showRuleInfo = record => {
    setVisibleRuleInfo(true)
    setRuleInfoReocord(record)
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Products',
      dataIndex: 'ruleProducts',
      key: 'ruleProducts',
      render: record => {
        let productsIds = []
        for (let i = 0; i < record.length; i++) {
          productsIds.push(` \n${record[i].exProductId} - ${record[i].name}`)
        }
        return (
          <div>
            {productsIds.map((item, key) => (
              <div key={key}>{item}</div>
            ))}
          </div>
        )
      },
    },
    {
      title: 'Processors',
      dataIndex: 'ruleProcessors',
      key: 'ruleProcessors',
      render: record => {
        let processorsIds = []
        for (let i = 0; i < record.length; i++) {
          processorsIds.push(` ${record[i].exProcessorId} - ${record[i].name}`)
        }
        return (
          <div>
            {processorsIds.map((item, key) => (
              <div key={key}>{item}</div>
            ))}
          </div>
        )
      },
    },
    {
      title: 'Actions',
      dataIndex: '',
      key: '',
      render: record => {
        return (
          <>
            <i
              className='zmdi zmdi-search zmdi-hc-2x users-list-action_item'
              color='blue'
              title='show more'
              type='primary'
              onClick={() => showRuleInfo(record)}
            />
            <Popconfirm
              title='Are you sure delete this Rule?'
              onConfirm={e => doConfirm(record.id, e)}
              onCancel={e => e?.stopPropagation()}
              okText='Yes'
              cancelText='No'
            >
              <i className='zmdi zmdi-delete zmdi-hc-2x txt-danger users-list-action_item' href='/#' title='Delete'></i>
            </Popconfirm>
          </>
        )
      },
    },
  ]

  function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&')
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url)
    if (!results) return null
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
  }

  const changePaginataion = data => {
    const { current, pageSize: paginationSize } = data
    setPageSize(paginationSize)
    setPageNumber(current)
  }

  return (
    <Main>
      <h4 className='txt-dart'>
        Rules for {existGroupName}({getParameterByName('groupId')})
      </h4>
      <Button type='primary' onClick={showModal} className='add-new-site_button'>
        Add Rule
      </Button>
      <Modal
        className='customer_modal'
        title={`new rule for ${existGroupName}(${getParameterByName('groupId')})`}
        visible={visible}
        footer={null}
        onCancel={handleCancel}
        width='70%'
      >
        <AddNewRule
          onCancel={handleCancel}
          groupId={getParameterByName('groupId')}
          campaignId={getParameterByName('campaignId')}
          date={new Date()}
        />
      </Modal>

      <Modal
        className='customer_modal'
        title={`update rule for ${existGroupName}(${getParameterByName('groupId')})`}
        visible={visibleRuleInfo}
        closable={false}
        footer={null}
        width='70%'
      >
        <UpdateRule onCancel={handleCancel} record={ruleIngoRecord} />
      </Modal>

      <Table
        columns={columns}
        dataSource={props.rules}
        bordered
        rowKey={record => record.id}
        onChange={changePaginataion}
        pagination={{
          total: props.total,
          showSizeChanger: true,
          pageSize,
          current: pageNumber,
        }}
      />
    </Main>
  )
}

const mapStateToPtops = store => ({
  rules: store.rules.list,
  groupIdForRule: store.rules.groupId,
  groups: store.groups.list,
})

const mapDispatchToPtops = {
  setRulesList: actions.setRulesList,
  deleteRule: actions.deleteRule,
  setGroupList: actionsGroups.setGroupList,
  setRuleProcessorsList: actions.setRuleProcessorsList,
  setRuleProductsList: actions.setRuleProductsList,
  setGroupIdToRule: actions.setGroupIdToRule,
  pauseRule: actions.pauseRule,
}

export default connect(mapStateToPtops, mapDispatchToPtops)(RulesListPage)
