import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button, Drawer, Input, Form, Radio, InputNumber } from 'antd'
import * as actions from '../actions'

const UpdateProcessor = ({ record, updateProcessor, fetchProcessorsList, compaignId }) => {
  const [isVisible, setVisible] = useState(false)
  const [form] = Form.useForm()
  const [error, setError] = useState('')
  const [crmType, setCrmType] = useState(
    window.localStorage.getItem('crm') && JSON.parse(window.localStorage.getItem('crm')).value
      ? JSON.parse(window.localStorage.getItem('crm')).value
      : 'response',
  )

  const onFinishHandler = async values => {
    if (error === '') {
      await updateProcessor({
        processorId: values.id,
        name: values.name,
        dailyCap: values.dailyCap,
        currency: values.currency,
        limit: values.limit,
      })
      if (compaignId && compaignId !== '') {
        await fetchProcessorsList({
          page: 1,
          size: 10,
          crmType: crmType,
          filters: { campaignId: +compaignId },
        })
      } else {
        await fetchProcessorsList({ page: 1, size: 10, crmType: crmType, filters: {} })
      }
    }
  }

  const rightLimit = value => {
    if (value === null) {
      setError('input monthli limit!')
    } else setError('')
  }

  return (
    <>
      <i
        className='zmdi zmdi-edit zmdi-hc-lg zmdi-hc-2x txt-warning users-list-action_item'
        title='Edit'
        type='primary'
        onClick={() => {
          setVisible(true)
        }}
      />
      <Drawer
        title='Update Processor'
        className={'add_product_popup'}
        onClose={() => setVisible(false)}
        visible={isVisible}
        forceRender
      >
        <Form layout='vertical' hideRequiredMark onFinish={onFinishHandler} form={form}>
          <Form.Item initialValue={record.name} name='name' label='Name'>
            <Input />
          </Form.Item>

          <Form.Item initialValue={record.externalId} name='id' label='ID'>
            <Input disabled />
          </Form.Item>

          <Form.Item initialValue={record.dailyCap} name='dailyCap' label='DailyCap'>
            <Input />
          </Form.Item>

          <Form.Item initialValue={record.limit} name='limit' label='Monthly Limit'>
            <InputNumber
              initialValue={1000}
              className='addClient_form-item'
              name='limit'
              label='Monthly Limit'
              style={{ width: '100%' }}
              formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
              onChange={rightLimit}
            />
          </Form.Item>

          <Form.Item initialValue={record.currency} name='currency' label='Currency'>
            <Radio.Group value={() => {}}>
              <Radio value={'usd'}>USD</Radio>
              <Radio value={'aud'}>AUD</Radio>
              <Radio value={'brl'}>BRL</Radio>
              <Radio value={'cad'}>CAD</Radio>
              <Radio value={'eur'}>EUR</Radio>
              <Radio value={'gbr'}>GBR</Radio>
              <Radio value={'inr'}>INR</Radio>
              <Radio value={'mxn'}>MXN</Radio>
              <Radio value={'nzd'}>NZD</Radio>
            </Radio.Group>
          </Form.Item>

          <div
            style={{
              position: 'absolute',
              left: 0,
              display: 'flex',
              justifyContent: 'space-between',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
            }}
          >
            <Button onClick={() => setVisible(false)}>Close</Button>
            <Button type='primary' htmlType='submit' className='add-new-site_button'>
              Update
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  )
}

const mapStateToProps = store => ({
  compaignId: store.companies.compaignId,
})

const mapDispatchToProps = {
  updateProcessor: actions.updateProcessor,
  fetchProcessorsList: actions.fetchProcessorsList,
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateProcessor)
