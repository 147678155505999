import { types } from './types'

const initState = {
  list: [],
  ruleProductslist: [],
  ruleProcessorslist: [],
  groupId: 0,
  total: 0,
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case types.SET_RULES_LIST: {
      const { data } = action
      return {
        ...state,
        list: data.items,
        total: data.total,
      }
    }
    case types.REMOVE_RULE: {
      state.list = state.list.filter(item => item.id !== action.data)
      state.total -= 1
      return {
        ...state,
      }
    }
    case types.PAUSE_RULE: {
      const { ruleId, pause } = action
      let stateCopy = { ...state }
      stateCopy.list = stateCopy.list.map(item => {
        if (item.id === ruleId) {
          item.pause = pause
        }
        return item
      })
      return {
        ...stateCopy,
      }
    }
    case types.SET_RULEPRODUCTS_LIST: {
      const { data } = action
      return {
        ...state,
        ruleProductslist: data.items,
      }
    }
    case types.SET_RULEPPROCESSORS_LIST: {
      const { data } = action
      return {
        ...state,
        ruleProcessorslist: data.items,
      }
    }
    case types.SET_GROUP_ID: {
      const { data } = action
      return {
        ...state,
        groupId: +data,
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
