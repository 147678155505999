import axios from '../../utils/axios'
import settings from '../../settings'
import { types } from './types'
import { guardFromErrors, extractErrorInfo } from '../../utils/graphqlHelper'
import { errorNotification, successNotification } from '../../utils/notificationHelper'

const SET_PIXELS_LIST = `
query pixelsListPage($page: Int, $size: Int, $filters: PixelFilters!) {
  pixelsListPage(page: $page, size: $size, filters: $filters) {
    items {
      id
      campaign_id
      site_id
      step
      product_id
      aff_id
      link
    }
    total
  }
}
`

const REMOVE_PIXEL = `
  mutation removePixel($pixelId: ID!) {
    removePixel(pixelId: $pixelId)
  }
`

const PIXEL_STAT = `
  query pixel($pixelId: ID!) {
    pixel(pixelId: $pixelId){
      status
      logs{
        status
        date
        customer_id
        trans_id
        aff_id
        product_id
        click_id
        s1
        s2
        s3
        s4
        s5
      }
    }
  }
`

const UPDATE_PIXEL = `
  mutation updatePixel($pixelId: ID!, $affId: String, $productId: Int, $step: Int!, $link: String!, $campaignId: Int!, $siteId: Int!) {
    updatePixel(pixelId: $pixelId, affId: $affId, productId: $productId, step: $step, link: $link, campaignId: $campaignId, siteId: $siteId)
  }
`

const CREATE_PIXEL = `
  mutation createPixel($affId: String, $productId: Int, $step: Int!, $link: String!, $campaignId: Int!, $siteId: Int!) {
    createPixel(affId: $affId, productId: $productId, step: $step, link: $link, campaignId: $campaignId, siteId: $siteId)
  }
`

//setPixelsList
export const setPixelsList =
  ({ page, size, filters = {} }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })

      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: SET_PIXELS_LIST,
        variables: { page, size, filters },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { pixelsListPage } = data
      if (!pixelsListPage) {
        throw new Error("Can't get pixels!")
      }

      dispatch({
        type: types.SET_PIXELS_LIST,
        data: pixelsListPage,
      })

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      dispatch(errorNotification(errorMessage))
    }
  }

export const getPixelStat = pixelId => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })
    console.log(pixelId)
    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: PIXEL_STAT,
      variables: { pixelId },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { pixel } = data
    if (!pixel) {
      throw new Error("Can't get logs!")
    }

    dispatch({
      type: types.GET_PIXEL_STAT,
      data: pixel,
    })

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
  }
}

//remove Pixel
export const removePixel = pixelId => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: REMOVE_PIXEL,
      variables: { pixelId },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { removePixel } = data
    if (!removePixel) {
      throw new Error("Can't remove Pixel")
    }

    dispatch({
      type: types.REMOVE_PIXEL,
      data: pixelId,
    })

    dispatch(successNotification('Remove Pixel successful'))

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
  }
}

//update Pixel
export const updatePixel = args => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })
    console.log(args)
    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: UPDATE_PIXEL,
      variables: {
        pixelId: args.pixelId,
        siteId: args.siteId,
        campaignId: args.campaignId,
        step: args.step,
        link: args.link,
        affId: args.affId,
        productId: args.productId,
      },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updatePixel } = data
    if (!updatePixel) {
      throw new Error("Can't update Pixel!")
    }

    dispatch(successNotification('Pixel update successful!'))

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log(errorMessage)
    dispatch(errorNotification(errorMessage))
  }
}

export const createPixel = args => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: CREATE_PIXEL,
      variables: {
        siteId: args.siteId,
        campaignId: args.campaignId,
        step: args.step,
        link: args.link,
        affId: args.affId,
        productId: args.productId,
      },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createPixel } = data
    if (!createPixel) {
      throw new Error("Can't update Pixel!")
    }

    dispatch(successNotification('Pixel update successful!'))

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
  }
}
