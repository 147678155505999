import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Table, Popconfirm } from 'antd'
import { CreateSite, UpdateSite, UpdateSites } from '../components'
import * as actions from '../actions'
import { Main } from '../../../components'

const SitesList = ({ sitesList, total, fetchSitesList, removeSite, compaigns, compaignId }) => {
  const [pageSize, setPageSize] = useState(10)
  const [pageNumber, setPageNumber] = useState(1)
  const [crmType, setCrmType] = useState(
    window.localStorage.getItem('crm') && JSON.parse(window.localStorage.getItem('crm')).value
      ? JSON.parse(window.localStorage.getItem('crm')).value
      : 'response',
  )

  useEffect(() => {
    const campaignItem = JSON.parse(window.localStorage.getItem('campaign'))
    if (campaignItem !== '' && campaignItem) {
      fetchSitesList({
        page: pageNumber,
        size: pageSize,
        filters: { campaignId: campaignItem.id },
      })
    } else {
      fetchSitesList({
        page: pageNumber,
        size: pageSize,
        crmType: crmType,
        filters: {},
      })
    }
  }, [pageSize, pageNumber, compaignId])

  const doConfirm = (siteId, e) => {
    e && e.stopPropagation()
    removeSite(siteId)
  }

  const columns = [
    {
      title: 'Id',
      dataIndex: 'externalId',
      key: 'externalId',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Campaign Name',
      dataIndex: 'campaignId',
      key: 'campaignId',
      render: record => {
        let name = ''
        // eslint-disable-next-line array-callback-return
        compaigns.map(item => {
          if (+record === +item.id) {
            name = item.name
          }
        })
        return (
          <div>
            {name}({record})
          </div>
        )
      },
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: record => (
        <>
          <UpdateSite record={record} />

          <Popconfirm
            title='Are you sure delete this user?'
            onConfirm={e => doConfirm(record.id, e)}
            onCancel={e => e?.stopPropagation()}
            okText='Yes'
            cancelText='No'
          >
            <i className='zmdi zmdi-delete zmdi-hc-2x txt-danger users-list-action_item' href='/#' title='Delete'></i>
          </Popconfirm>
        </>
      ),
    },
  ]

  const changePaginataion = data => {
    const { current, pageSize: paginationSize } = data
    setPageSize(paginationSize)
    setPageNumber(current)
  }

  return (
    <Main>
      <h4 className='txt-dark'>Sites</h4>
      <CreateSite callback={() => fetchSitesList({ page: pageNumber, size: pageSize, crmType: crmType })} />
      <UpdateSites />
      <Table
        columns={columns}
        dataSource={sitesList}
        bordered
        rowKey={record => record.id}
        onChange={changePaginataion}
        pagination={{
          total,
          showSizeChanger: true,
          pageSize,
          current: pageNumber,
        }}
      />
    </Main>
  )
}

const mapStateToProps = store => ({
  sitesList: store.sites.list,
  total: store.sites.total,
  compaigns: store.companies.list,
  compaignId: store.companies.compaign,
})

const mapDispatchToProps = {
  fetchSitesList: actions.fetchSitesList,
  removeSite: actions.removeSite,
}

export default connect(mapStateToProps, mapDispatchToProps)(SitesList)
