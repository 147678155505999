import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, Drawer, Form, Select } from 'antd'
import * as actions from '../actions'
import * as siteActions from '../../Sites/actions'
import * as companyActions from '../../Companies/actions'

const DownloadProduct = ({
  downloadProduct,
  campaigns,
  fetchSitesList,
  fetchCompaniesList,
  callback,
  setProductsList,
}) => {
  const [isVisible, setVisible] = useState(false)
  const [form] = Form.useForm()
  const [crmType, setCrmType] = useState(
    window.localStorage.getItem('crm') && JSON.parse(window.localStorage.getItem('crm')).value
      ? JSON.parse(window.localStorage.getItem('crm')).value
      : 'response',
  )

  useEffect(() => {
    fetchSitesList({ page: 1, size: 100, crmType: crmType })
    fetchCompaniesList({ page: 1, size: 100, crmType: crmType })
  }, [fetchCompaniesList, fetchSitesList])

  const onFinishHandler = async values => {
    await downloadProduct({ campaignId: values.campaignId, action: 'download' })
    callback()
    setProductsList({ page: 1, size: 10, crmType: crmType, filters: {} })
    setVisible(false)
    form.resetFields()
  }

  return (
    <>
      <Button type='primary' className='add-new-site_button' onClick={() => setVisible(true)}>
        Import a Products
      </Button>

      <Drawer
        title='Import Products'
        className={'add_product_popup'}
        onClose={() => setVisible(false)}
        visible={isVisible}
        forceRender
      >
        <Form layout='vertical' hideRequiredMark onFinish={onFinishHandler} form={form}>
          <Form.Item
            name='campaignId'
            label='Select Company'
            rules={[{ required: true, message: 'Please select company!' }]}
          >
            <Select>
              {campaigns.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <div
            style={{
              position: 'absolute',
              left: 0,
              display: 'flex',
              justifyContent: 'space-between',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
            }}
          >
            <Button onClick={() => setVisible(false)}>Close</Button>
            <Button type='primary' htmlType='submit' className='add-new-site_button'>
              Import
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  )
}

const mapStateToProps = store => ({
  sites: store.sites.list,
  campaigns: store.companies.list,
})

const mapDispatchToProps = {
  downloadProduct: actions.downloadProduct,
  setProductsList: actions.setProductsList,
  fetchSitesList: siteActions.fetchSitesList,
  fetchCompaniesList: companyActions.fetchCompaniesList,
}

export default connect(mapStateToProps, mapDispatchToProps)(DownloadProduct)
