import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Button, Drawer, Input, Form, Table, Popconfirm, Modal } from 'antd'
import * as actions from '../actions'
import { UpdatePixel } from './index'

const PixelStat = ({ record, setPixelsList, getPixelStat, logs }) => {
  const [isVisible, setVisible] = useState(false)
  const [form] = Form.useForm()
  const [crmType, setCrmType] = useState(
    window.localStorage.getItem('crm') && JSON.parse(window.localStorage.getItem('crm')).value
      ? JSON.parse(window.localStorage.getItem('crm')).value
      : 'response',
  )
  useEffect(() => {
    console.log(record)
    record && isVisible && getPixelStat(+record.id)
  }, [isVisible])

  const columns = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'AffID',
      dataIndex: 'aff_id',
      key: 'aff_id',
    },
    {
      title: 'Customer ID',
      dataIndex: 'customer_id',
      key: 'customer_id',
    },
    {
      title: 'Trans ID',
      dataIndex: 'trans_id',
      key: 'trans_id',
    },
  ]
  const [pageSize, setPageSize] = useState(10)
  const [pageNumber, setPageNumber] = useState(1)
  const changePaginataion = data => {
    const { current, pageSize: paginationSize } = data
    setPageSize(paginationSize)
    setPageNumber(current)
  }

  return (
    <>
      <i
        className='zmdi zmdi-chart zmdi-hc-2x text-green users-list-action_item'
        title='Logs'
        type='primary'
        onClick={() => {
          setVisible(true)
        }}
      />
      <Modal
        title='Pixel Logs'
        className={'add_pixel_popup'}
        onClose={() => setVisible(false)}
        visible={isVisible}
        forceRender
        closable={true}
        width={'90%'}
        footer={
          <div>
            <Button onClick={() => setVisible(false)}>Close</Button>
          </div>
        }
      >
        <Table
          columns={columns}
          dataSource={logs}
          bordered
          onChange={changePaginataion}
          rowKey={record => record.id}
          scroll={{ x: 768, y: 10000 }}
          pagination={{
            showSizeChanger: true,
            pageSize,
            current: pageNumber,
          }}
        />

        <div
          style={{
            position: 'absolute',
            left: 0,
            display: 'flex',
            justifyContent: 'space-between',
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
          }}
        ></div>
      </Modal>
    </>
  )
}

const mapStateToProps = store => ({
  logs: store.pixels.logs,
})
const mapDispatchToProps = {
  getPixelStat: actions.getPixelStat,
}

export default connect(mapStateToProps, mapDispatchToProps)(PixelStat)
