import axios from '../../utils/axios'
import settings from '../../settings'
import { types } from './types'
import { guardFromErrors, extractErrorInfo } from '../../utils/graphqlHelper'
import { errorNotification, successNotification } from '../../utils/notificationHelper'

const FETCH_PROCESSORS_LIST = `
  query processorsListPage($page: Int, $size: Int, $filters: ProcessorsFilters!, $crmType: String) {
    processorsListPage(page: $page, size: $size, filters: $filters, crmType: $crmType) {
      items {
        id
        name 
        campaignId
        externalId
        percentageUsed
        limit
        dailyCap
      }
      total
    }
  }
`

const ADD_NEW_PROCESSOR = `
  mutation createProcessor($campaignId: ID!, $action: String!) {
    createProcessor(campaignId: $campaignId, action: $action)
}
`

const PARSE_PROCESSORS = `
  mutation importCSVProcessor($campaignId: ID!, $processors: [ProcessorsInput]) {
    importCSVProcessor(campaignId: $campaignId, processors: $processors)
}
`

const UPDATE_PROCESSOR = `
  mutation updateProcessor($processorId: ID!, $name: String, $dailyCap: String, $currency: String, $limit: String) {
    updateProcessor(processorId: $processorId, name: $name, dailyCap: $dailyCap, currency: $currency, limit: $limit)
  }
`

const REMOVE_PROCESSOR = `
  mutation removeProcessor($processorId: ID!) {
    removeProcessor(processorId: $processorId)
  }
`

// fetchProcessorsList
export const fetchProcessorsList =
  ({ page, size, crmType, filters = {} }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })

      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: FETCH_PROCESSORS_LIST,
        variables: { page, size, filters, crmType },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is ampty!')
      }

      const { processorsListPage } = data
      console.log('data: ', data)
      if (!processorsListPage) {
        throw new Error("Can't get processors")
      }

      dispatch({
        type: types.SET_PROCESSORS_LIST,
        data: processorsListPage,
      })

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      dispatch(errorNotification(errorMessage))
    }
  }

// createProcessor
export const createProcessor = formData => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios({
      method: 'post',
      url: `${settings.backendUrl}/graphql`,
      data: {
        query: ADD_NEW_PROCESSOR,
        variables: { ...formData },
      },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response Body Is Empty!')
    }

    const { createProcessor } = data
    if (!createProcessor) {
      throw new Error('The operation was not successful!')
    }

    dispatch(successNotification('Operation Completed Successfully!'))

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
  }
}

// updateProcessor
export const updateProcessor = args => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { processorId, name, dailyCap, currency, limit } = args

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: UPDATE_PROCESSOR,
      variables: {
        processorId,
        name,
        dailyCap,
        currency,
        limit: limit.toString(),
      },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateProcessor } = data
    if (!updateProcessor) {
      throw new Error("Can't Update Processor")
    }

    dispatch(successNotification('Processor Update Successful'))

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const arrorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(arrorMessage))
  }
}

// removeProcessor
export const removeProcessor = processorId => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: REMOVE_PROCESSOR,
      variables: { processorId },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { removeProcessor } = data
    if (!removeProcessor) {
      throw new Error("Can't remove processor")
    }

    dispatch(successNotification('Remove Processor successful'))

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
  }
}

export const sendSvgData = formData => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    let csvProcessors = []
    for (let i = 0; i < formData.data.length; i++) {
      let checkId = false
      let checkIdNumberInFile = 0
      let checkTitle = false
      let checkTitleNumberInFile = 0
      let checkMTD = false
      let checkMTDNumberInFile = 0
      let checkMonthlyLimit = false
      let checkMonthlyLimitNumberInFile = 0

      for (let j = 0; j < formData.data[0].length; j++) {
        if (formData.data[0][j] === 'id' || formData.data[0][j] === 'ID') {
          checkId = true
          checkIdNumberInFile = j
        }
      }

      for (let j = 0; j < formData.data[0].length; j++) {
        if (formData.data[0][j] === 'title' || formData.data[0][j] === 'Title') {
          checkTitle = true
          checkTitleNumberInFile = j
        }
      }

      for (let j = 0; j < formData.data[0].length; j++) {
        if (formData.data[0][j] === 'mtd' || formData.data[0][j] === 'MTD') {
          checkMTD = true
          checkMTDNumberInFile = j
        }
      }

      for (let j = 0; j < formData.data[0].length; j++) {
        if (formData.data[0][j] === 'monthlyLimit' || formData.data[0][j] === 'MonthlyLimit') {
          checkMonthlyLimit = true
          checkMonthlyLimitNumberInFile = j
        }
      }
      if (checkId && checkTitle && checkMTD && checkMonthlyLimit) {
        if (i > 0 && i < formData.data.length && formData.data[i].length > 1) {
          csvProcessors.push({
            id: formData.data[i][checkIdNumberInFile],
            name: formData.data[i][checkTitleNumberInFile],
            used: formData.data[i][checkMTDNumberInFile]
              .substring(1)
              .replace(/\s/g, '')
              .replace(',', '')
              .replace('--', '0'),
            monthlyLimit: formData.data[i][checkMonthlyLimitNumberInFile]
              .substring(1)
              .replace(/\s/g, '')
              .replace(',', '')
              .replace('--', '0'),
          })
        }
      } else {
        throw new Error('CSV file not valid!')
      }
    }

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: PARSE_PROCESSORS,
      variables: { campaignId: formData.campaignId, processors: csvProcessors },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response Body Is Empty!')
    }

    const { importCSVProcessor } = data
    if (!importCSVProcessor) {
      throw new Error('The operation was not successful!')
    }

    dispatch(successNotification('Download Processors successful'))

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
  }
}
