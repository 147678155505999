import { types } from './types'

const initState = {
  list: [],
  total: 0,
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case types.SET_GROUP_LIST: {
      const { data } = action
      return {
        ...state,
        list: data.items,
        total: data.total,
      }
    }
    case types.REMOVE_GROUP: {
      state.list = state.list.filter(item => item.id !== action.data)
      state.total -= 1
      return {
        ...state,
      }
    }
    case types.PAUSE_CORP: {
      const { corpId, pause } = action
      console.log('pausered: ', pause)
      let stateCopy = { ...state }
      stateCopy.list = stateCopy.list.map(item => {
        if (item.id === +corpId) {
          item.pause = pause
        }
        return item
      })
      return {
        ...stateCopy,
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
