import React, { useState, useEffect } from 'react'
import { Button, Drawer, Select, Form } from 'antd'
import CSVReader from 'react-csv-reader'
import * as actions from '../actions'
import * as companyActions from '../../Companies/actions'
import { connect } from 'react-redux'

const AddCsv = ({ sendSvgData, campaigns, fetchCompaniesList, fetchProcessorsList }) => {
  const [isVisible, setVisible] = useState(false)
  const [customData, setCustomData] = useState(false)
  const [form] = Form.useForm()
  const [crmType, setCrmType] = useState(
    window.localStorage.getItem('crm') && JSON.parse(window.localStorage.getItem('crm')).value
      ? JSON.parse(window.localStorage.getItem('crm')).value
      : 'response',
  )

  useEffect(() => {
    fetchCompaniesList({ page: 1, size: 100, crmType: crmType })
  }, [])

  const onFinishHandler = async value => {
    await sendSvgData({
      data: customData,
      campaignId: +value.campaignId,
    })
    form.resetFields()
    fetchProcessorsList({ page: 1, size: 1000, crmType: crmType, filters: {} })
    setVisible(false)
  }

  const svgData = data => {
    setCustomData(data)
  }

  return (
    <>
      <Button type='primary' onClick={() => setVisible(true)}>
        Update Processors From CSV
      </Button>
      <Drawer
        title='Update Processors From CSV'
        onClose={() => setVisible(false)}
        visible={isVisible}
        forceRender
        closable
        className={'add_product_popup'}
      >
        <Form layout='vertical' hideRequiredMark onFinish={onFinishHandler} form={form}>
          <Form.Item
            name='campaignId'
            label='Select Company'
            rules={[{ required: true, message: 'Please select company!' }]}
          >
            <Select>
              {campaigns.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <CSVReader label='Select CSV file' inputStyle={{ color: '#4f2a1b' }} onFileLoaded={data => svgData(data)} />

          <Button htmlType='submit' type='submit' style={{ marginTop: '20px' }}>
            Submit
          </Button>
        </Form>
      </Drawer>
    </>
  )
}

const mapStateToProps = store => ({
  campaigns: store.companies.list,
})

const mapDispatchToProps = {
  sendSvgData: actions.sendSvgData,
  fetchCompaniesList: companyActions.fetchCompaniesList,
  fetchProcessorsList: actions.fetchProcessorsList,
}

export default connect(mapStateToProps, mapDispatchToProps)(AddCsv)
