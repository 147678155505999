import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as actions from '../actions'

const StartTask = ({ record, updateStatusTask, getStatusAndStartDate, getCustomersInfo }) => {
  const [intervalId, setIntervalId] = useState(null)

  useEffect(() => {
    return () => clearInterval(intervalId)
  })

  const updateStatus = async (taskId, action) => {
    const successStatus = await updateStatusTask({ taskId, action })
    if (successStatus) {
      setTimeout(() => getStatusAndStartDate(taskId), 2000)
      if (action === 'run_task') {
        const id = setInterval(() => {
          getCustomersInfo(taskId)
          getStatusAndStartDate(taskId)
        }, 30000)
        setIntervalId(id)
      } else {
        clearInterval(intervalId)
      }
    }
  }

  return (
    <>
      <i
        className='zmdi zmdi-play-circle zmdi-hc-2x users-list-action_item'
        title='Start'
        type='primary'
        onClick={async () => {
          await updateStatus(record.id, 'run_task')
        }}
      />

      <i
        className='zmdi zmdi-stop zmdi-hc-3x zmdi-hc-lg users-list-action_item'
        title='Stop'
        type='primary'
        onClick={async () => {
          await updateStatus(record.id, 'stop_task')
        }}
      />

      <i
        className='zmdi zmdi-pause-circle zmdi-hc-2x users-list-action_item'
        title='Pause'
        type='primary'
        onClick={async () => {
          await updateStatus(record.id, 'pause_task')
        }}
      />
    </>
  )
}

const mapStateToProps = store => ({})

const mapDispatchToProps = {
  setTasksList: actions.setTasksList,
  updateStatusTask: actions.updateStatusTask,
  getStatusAndStartDate: actions.getStatusAndStartDate,
  getCustomersInfo: actions.getCustomersInfo,
}

export default connect(mapStateToProps, mapDispatchToProps)(StartTask)
