import React from 'react'
import { connect } from 'react-redux'
import { Input, Select, Form } from 'antd'
import * as actions from '../actions'
import usaStates from '../../../usaStates'
import { CreateAndBackButtons } from '../../../components'

const AddClient = ({ createClient }) => {
  const [form] = Form.useForm()

  const onFinishHandler = async formData => {
    createClient(formData)
    form.resetFields()
  }

  return (
    <div>
      <Form layout='vertical' hideRequiredMark onFinish={onFinishHandler} form={form} className='addClient_form'>
        <Form.Item
          className='addClient_form-item'
          name='email'
          label='Email'
          rules={[{ required: true, message: 'Incorrect email', type: 'email' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          className='addClient_form-item'
          name='country'
          label='country'
          rules={[{ required: true, message: 'Incorrect country', min: 2 }]}
        >
          <Select>
            <Select.Option value='US'>United States</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          className='addClient_form-item'
          name='password'
          label='Password'
          rules={[{ required: true, message: 'Incorrect password', min: 6 }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          className='addClient_form-item'
          name='firstName'
          label='First Name'
          rules={[{ required: true, message: 'Incorrect first name', min: 2 }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          className='addClient_form-item'
          name='lastName'
          label='Last Name'
          rules={[{ required: true, message: 'Incorrect last name', min: 2 }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          className='addClient_form-item'
          name='companyName'
          label='Company Name'
          rules={[{ required: true, message: 'Incorrect company name' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          className='addClient_form-item'
          name='address1'
          label='Address1'
          rules={[{ required: true, message: 'Incorrect address' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item className='addClient_form-item' name='address2' label='Address2'>
          <Input />
        </Form.Item>
        <Form.Item
          className='addClient_form-item'
          name='phone'
          label='Phone Number'
          // eslint-disable-next-line no-useless-escape
          rules={[
            {
              required: true,
              message: 'Incorrect phone number',
              pattern: new RegExp(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/, 'im'),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          className='addClient_form-item'
          name='city'
          label='City'
          rules={[{ required: true, message: 'Incorrect city' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          className='addClient_form-item'
          name='state'
          label='State'
          rules={[{ required: true, message: 'Incorrect state' }]}
        >
          <Select>
            {usaStates.map(state => (
              <Select.Option value={state} key={state}>
                {state}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          className='addClient_form-item'
          name='zip'
          label='Zip Code'
          rules={[{ required: true, message: 'Incorrect zip code' }]}
        >
          <Input />
        </Form.Item>
        <CreateAndBackButtons adress={'/clients-list'} />
      </Form>
    </div>
  )
}

const mapDispatchToProps = {
  createClient: actions.createClient,
}

export default connect(null, mapDispatchToProps)(AddClient)
