import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Button, Drawer, Input, Form, Table, Popconfirm, Modal, DatePicker } from 'antd'
import * as actions from '../actions'
import { UpdateRebatesCampaign } from './index'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

const RebatesCampaignStat = ({ record, setRebatesCampaignsList, getRebatesCampaignStat, logs }) => {
  const [isVisible, setVisible] = useState(false)
  const [date, setDate] = useState(dayjs().format('YYYY-MM-DD'))
  const [declinesLogs, setDeclinesLogs] = useState([])

  const columns = [
    {
      title: 'Decline',
      dataIndex: 'reason',
      key: 'reason',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Card #',
      dataIndex: 'cc_number',
      key: 'cc_number',
    },
  ]
  useEffect(() => {
    isVisible && getRebatesCampaignStat(record.id, date)
  }, [isVisible, date])
  useEffect(() => {
    logs && setDeclinesLogs(logs.declines_logs)
  }, [logs])

  return (
    <>
      <i
        className='zmdi zmdi-chart zmdi-hc-2x text-green users-list-action_item'
        title='Logs'
        type='primary'
        onClick={() => {
          setVisible(true)
        }}
      />
      <Modal
        title='RebatesCampaign Logs'
        className={'add_rebatesCampaign_popup'}
        onClose={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        visible={isVisible}
        forceRender
        closable={true}
        width={'90%'}
        footer={
          <div>
            <Button onClick={() => setVisible(false)}>Close</Button>
          </div>
        }
      >
        <DatePicker
          defaultValue={dayjs()}
          allowClear={true}
          format={'YYYY-MM-DD'}
          showTime={false}
          changeOnScroll={true}
          onChange={value => {
            console.log(value)
            setDate(value.format('YYYY-MM-DD'))
          }}
        />
        <div className={'total_stat'}>
          <h3>Total Stat</h3>
          Total Success: {logs && logs.total_success}
          <br />
          Total Declines: {logs && logs.total_declines}
          <br />
          Success Rate: {logs && ((logs.total_success / (logs.total_success + logs.total_declines)) * 100).toFixed(2)}%
          <br />
        </div>
        <hr />
        <div className={'total_stat'}>
          <h3>{date} Stat</h3>
          Success: {logs && logs.success}
          <br />
          Declines: {logs && logs.declines}
          <br />
          Success Rate: {logs && ((logs.success / (logs.success + logs.declines)) * 100).toFixed(2)}%
          <br />
        </div>
        {declinesLogs?.length > 0 && (
          <>
            <hr />
            <Table columns={columns} dataSource={declinesLogs} bordered rowKey={record => record.id} />
          </>
        )}
      </Modal>
    </>
  )
}

const mapStateToProps = store => ({
  logs: store.rebatesCampaigns.logs,
})
const mapDispatchToProps = {
  getRebatesCampaignStat: actions.getRebatesCampaignStat,
}

export default connect(mapStateToProps, mapDispatchToProps)(RebatesCampaignStat)
