import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import * as actions from './actions'
import logo from '../../assets/images/logo.png'

const Signup = props => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
  })
  const [errors, setErrors] = useState({})
  const [redirect, setRedirect] = useState(false)

  const hadlerFormData = (event, key) => {
    const newFormData = { ...formData }
    const newErrors = { ...errors }
    newErrors[key] = !event.target.value ? "This field can't be empty" : ''

    if (key === 'email' && event.target.value.search(/.+@.+\..+/i) === -1) {
      newErrors.email = 'Invalid email'
    }
    setErrors(newErrors)
    newFormData[key] = event.target.value
    setFormData(newFormData)
  }

  const confirmPassword = event => {
    setErrors({ ...errors, confirmPassword: event.target.value !== formData.password ? "Password isn't match" : '' })
    formData.confirmPassword = event.target.value
    setFormData(formData)
  }

  const handlerSubmit = () => {
    let newErrors = {}
    Object.keys(formData).forEach(item => {
      if (!formData[item]) {
        newErrors[item] = "This field can't be empty"
      }
    })
    newErrors = { ...newErrors, ...errors }
    setErrors(newErrors)
    let result = true
    Object.keys(newErrors).forEach(item => {
      result = result && !newErrors[item]
    })

    if (result) {
      props.signUp({ ...formData, history: props.history })
      setRedirect(true)
    }
  }

  return (
    <div className='wrapper  pa-0'>
      {redirect && <Redirect to='/login' />}
      <header className='sp-header'>
        <div className='sp-logo-wrap pull-left'>
          <Link className='goIndex' to='/'>
            <img className='brand-img mr-10' src={logo} alt='brand' />
            <span className='brand-text'>Droopy</span>
          </Link>
        </div>
        <p className='form-group mb-0 pull-right'>
          <span className='inline-block pr-10'>Already have an account?</span>
          <Link className='inline-block btn btn-success btn-rounded btn-outline' to='/'>
            Sign In
          </Link>
        </p>
        <div className='clearfix'></div>
      </header>

      <div className='page-wrapper pa-0 ma-0 auth-page'>
        <div className='container-fluid'>
          <div className='table-struct full-width full-height'>
            <div className='table-cell vertical-align-middle auth-form-wrap'>
              <div className='auth-form  ml-auto mr-auto no-float'>
                <div className='row'>
                  <div className='col-sm-12 col-xs-12'>
                    <div className='mb-30'>
                      <h3 className='text-center txt-dark mb-10'>Sign up to Droopy</h3>
                      <h6 className='text-center nonecase-font txt-grey'>Enter your details below</h6>
                    </div>
                    <div className='form-wrap'>
                      <div>
                        <div className='form-group'>
                          <label className='control-label mb-10' htmlFor='exampleInputName_1'>
                            First Name
                          </label>
                          <input
                            type='text'
                            onChange={event => hadlerFormData(event, 'firstName')}
                            className='form-control'
                            required
                            placeholder='First Name'
                          />
                          {errors.firstName && <p>{errors.firstName}</p>}
                        </div>
                        <div className='form-group'>
                          <label className='control-label mb-10' htmlFor='exampleInputName_1'>
                            Last Name
                          </label>
                          <input
                            type='text'
                            onChange={event => hadlerFormData(event, 'lastName')}
                            className='form-control'
                            required
                            placeholder='Last Name'
                          />
                          {errors.lastName && <p>{errors.lastName}</p>}
                        </div>
                        <div className='form-group'>
                          <label className='control-label mb-10' htmlFor='exampleInputEmail_2'>
                            Email address
                          </label>
                          <input
                            type='email'
                            onChange={event => hadlerFormData(event, 'email')}
                            className='form-control'
                            required
                            placeholder='Email'
                          />
                          {errors.email && <p>{errors.email}</p>}
                        </div>
                        <div className='form-group'>
                          <label className='pull-left control-label mb-10' htmlFor='exampleInputpwd_2'>
                            Password
                          </label>
                          <input
                            type='password'
                            onChange={event => hadlerFormData(event, 'password')}
                            className='form-control'
                            required
                            placeholder='Password'
                          />
                          {errors.password && <p>{errors.password}</p>}
                        </div>
                        <div className='form-group'>
                          <label className='pull-left control-label mb-10' htmlFor='exampleInputpwd_3'>
                            Confirm Password
                          </label>
                          <input
                            type='password'
                            onChange={confirmPassword}
                            className='form-control'
                            required
                            placeholder='Password'
                          />
                          {errors.confirmPassword && <p>{errors.confirmPassword}</p>}
                        </div>
                        <div className='form-group text-center'>
                          <button className='btn btn-success btn-rounded' onClick={handlerSubmit}>
                            sign Up
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = {
  signUp: actions.signUp,
}

export default connect(null, mapDispatchToProps)(Signup)
