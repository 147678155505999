import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, Drawer, Input, Form, Select, InputNumber } from 'antd'
import * as actions from '../actions'
import * as compaignsActions from '../../Companies/actions'

const UpdateGroup = ({ record, fetchCompaniesList, campaigns, updateGroup, setGroupList }) => {
  const [isVisible, setVisible] = useState(false)
  const [form] = Form.useForm()
  const [crmType, setCrmType] = useState(
    window.localStorage.getItem('crm') && JSON.parse(window.localStorage.getItem('crm')).value
      ? JSON.parse(window.localStorage.getItem('crm')).value
      : 'response',
  )

  useEffect(() => {
    fetchCompaniesList({ page: 1, size: 100, crmType: crmType })
  }, [fetchCompaniesList])

  const onFinishHandler = async values => {
    await updateGroup({
      groupId: +values.groupId,
      campaignId: +values.campaignId,
      transMax: values.transMax,
      name: values.name,
    })
    await setGroupList({ page: 1, size: 10, crmType: crmType, filters: { type: 'group' } })
    setVisible(false)
    form.resetFields()
  }

  return (
    <>
      <i
        className='zmdi zmdi-edit zmdi-hc-2x zmdi-hc-lg txt-warning users-list-action_item'
        title='Edit'
        type='primary'
        onClick={() => {
          setVisible(true)
        }}
      />
      <Drawer
        title='Update Group'
        className={'add_product_popup'}
        onClose={() => setVisible(false)}
        visible={isVisible}
        forceRender
      >
        <Form layout='vertical' hideRequiredMark onFinish={onFinishHandler} form={form}>
          <Form.Item
            initialValue={record.campaignId}
            name='campaignId'
            label='Select a campaign'
            rules={[{ required: true, message: 'Select campaign!' }]}
          >
            <Select>
              {campaigns.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            initialValue={record.name}
            name='name'
            label='Input Corporation Name'
            rules={[{ required: true, message: 'Input Corporation Name!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            initialValue={record.transMax}
            name='transMax'
            label='Input transMax Value'
            rules={[
              {
                required: true,
                message: 'Input transMax Value!',
                type: 'number',
              },
            ]}
          >
            <InputNumber />
          </Form.Item>

          <Form.Item initialValue={record.id} name='groupId' label='Corporation ID'>
            <Input disabled />
          </Form.Item>

          <div
            style={{
              position: 'absolute',
              left: 0,
              display: 'flex',
              justifyContent: 'space-between',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
            }}
          >
            <Button onClick={() => setVisible(false)}>Close</Button>
            <Button type='primary' htmlType='submit' className='add-new-site_button'>
              Update
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  )
}

const mapStateToProps = store => ({
  campaigns: store.companies.list,
})

const mapDispatchToProps = {
  fetchCompaniesList: compaignsActions.fetchCompaniesList,
  updateGroup: actions.updateGroup,
  setGroupList: actions.setGroupList,
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateGroup)
