import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Drawer,
  Input,
  Form,
  InputNumber,
  Select,
  Modal,
  Col,
  Row,
  Checkbox,
  Table,
  Popconfirm,
  DatePicker,
  TimePicker,
} from 'antd'
import dayjs from 'dayjs'
import * as actions from '../actions'
import * as compaignsActions from '../../Companies/actions'
import * as sitesActions from '../../Sites/actions'
import * as productsActions from '../../Products/actions'
import * as processorsActions from '../../Processors/actions'
import { DeleteFilled, EditFilled } from '@ant-design/icons'

const EditTimeWindow = ({ window, editTimeWindow }) => {
  const [isVisible, setVisible] = useState(false)
  const [form] = Form.useForm()

  const onFinishHandler = async values => {
    setVisible(false)
    window.startTime = values.startTime.format('HH:mm:ss')
    window.endTime = values.endTime.format('HH:mm:ss')
    //window.totalCount = values.mcCount + values.visaCount
    window.mcCount = values.mcCount
    window.visaCount = values.visaCount
    editTimeWindow()
    form.resetFields()
  }
  return (
    <>
      <EditFilled
        onClick={() => {
          setVisible(true)
          form.resetFields()
        }}
      />
      <Modal
        title='Edit Time Window'
        className={'rebatesCampaign_popup'}
        onClose={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        visible={isVisible}
        footer={[
          <Button key='back' onClick={() => setVisible(false)}>
            Cancel
          </Button>,
          <Button key='submit' type='primary' onClick={() => form.submit()}>
            Edit
          </Button>,
        ]}
      >
        <Form
          hideRequiredMark
          onFinish={onFinishHandler}
          form={form}
          labelCol={{ span: 24, offset: 0 }}
          wrapperCol={{ span: 24, offset: 0 }}
        >
          <Row gutter={8}>
            <Col sm={12}>
              <Form.Item
                name='startTime'
                initialValue={dayjs('2024-04-04 ' + window.startTime)}
                label='Start Time'
                rules={[{ required: true, message: 'Please Choose Start Time!' }]}
              >
                <TimePicker allowClear={false} format={'HH:mm:ss'} changeOnScroll={true}></TimePicker>
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                name='endTime'
                initialValue={dayjs('2024-04-04 ' + window.endTime)}
                label='End Time'
                rules={[{ required: true, message: 'Please input End Time!' }]}
              >
                <TimePicker allowClear={false} format={'HH:mm:ss'} changeOnScroll={true}></TimePicker>
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                name='mcCount'
                initialValue={window.mcCount}
                label='MC Count'
                rules={[{ required: true, message: 'Please input MC Count!' }]}
              >
                <InputNumber min={0} precision={0} />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                name='visaCount'
                initialValue={window.visaCount}
                label='Visa Count'
                rules={[{ required: true, message: 'Please input Visa Count!' }]}
              >
                <InputNumber min={0} precision={0} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

export default EditTimeWindow
