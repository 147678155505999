import axios from '../../utils/axios'
import settings from '../../settings'
import { types } from './types'
import { guardFromErrors, extractErrorInfo } from '../../utils/graphqlHelper'
import { errorNotification, successNotification } from '../../utils/notificationHelper'

const SET_GROUP_LIST = `
query groupsListPage($page: Int, $size: Int, $crmType: String, $filters: GroupsFiltersType!) {
    groupsListPage(page: $page, size: $size, crmType: $crmType, filters: $filters) {
    items {
      id
      campaignId
      name
      transMax
      groupToGroups{
        id
        groupId
        percent
        spareGroupId
      }
      pause
    }
    total
  }
}
`

const CREATE_GROUP = `
  mutation createGroup($name: String!, $campaignId: ID!, $transMax: Int!, $type: String, $crmType: String) {
    createGroup(name: $name, campaignId: $campaignId, transMax: $transMax, type: $type, crmType: $crmType) {
      id
    }
  }
`

const UPDATE_GROUP = `
  mutation updateGroup($name: String!, $campaignId: ID!, $groupId: ID!, $transMax: Int!, $groupToGroups: [GroupToGroupsTypeInput]) {
    updateGroup(name: $name, campaignId: $campaignId, groupId: $groupId, transMax: $transMax,groupToGroups: $groupToGroups)
  }
`

const REMOVE_GROUP = `
  mutation deleteGroup($groupId: ID!) {
    deleteGroup(groupId: $groupId)
  }
`

const PAUSE_CORP = `
  mutation pauseCorp($corpId: ID!, $pause: Boolean!) {
    pauseCorp(corpId: $corpId, pause: $pause)
  }
`

const COPY_CORP = `
  mutation copyGroup($name: String!, $groupId: ID!) {
    copyGroup(name: $name, groupId: $groupId) {
      id
    }
  }
`

// export const pauseCorp =
//   ({ corpId, pause }) =>
//   async (dispatch) => {
//     console.log('pause actions: ', pause)
//     try {
//       dispatch({
//         type: 'SET_LOADING_STATE',
//         active: true,
//       })

//       dispatch({
//         type: types.PAUSE_CORP,
//         corpId,
//         pause,
//       })

//       dispatch({
//         type: 'SET_LOADING_STATE',
//         active: false,
//       })
//     } catch (errors) {
//       const errorMessage = extractErrorInfo(errors)
//       dispatch(errorNotification(errorMessage))
//     }
//   }

//pauseCorp
export const pauseCorp =
  ({ corpId, pause }) =>
  async dispatch => {
    console.log('pause: ', pause)
    try {
      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: PAUSE_CORP,
        variables: { corpId: +corpId, pause },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { pauseCorp } = data
      if (!pauseCorp) {
        throw new Error("Can't pause rules")
      }

      dispatch({
        type: types.PAUSE_CORP,
        corpId,
        pause,
      })

      dispatch(successNotification('Action rules successful'))
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      dispatch(errorNotification(errorMessage))
    }
  }

//pauseCorp
export const copyCorp =
  ({ groupId, name }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })

      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: COPY_CORP,
        variables: { groupId, name },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { copyGroup } = data
      if (!copyGroup) {
        throw new Error("Can't Copy")
      }

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })

      dispatch(successNotification('Copy successful'))
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      dispatch(errorNotification(errorMessage))
    }
  }

//updateGroup
export const updateGroup =
  ({ name, campaignId, groupId, transMax, groupToGroups }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })

      let { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: UPDATE_GROUP,
        variables: {
          name,
          campaignId,
          groupId,
          transMax,
          groupToGroups,
        },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { updateGroup } = data
      if (!updateGroup) {
        throw new Error("Can't update Rule")
      }

      dispatch(successNotification('Update Corporation successful!'))

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      dispatch(errorNotification(errorMessage))
    }
  }

//removeGroup
export const removeGroup = groupId => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })
    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: REMOVE_GROUP,
      variables: { groupId },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { deleteGroup } = data
    if (!deleteGroup) {
      throw new Error("Can't remove group")
    }

    dispatch({
      type: types.REMOVE_GROUP,
      data: groupId,
    })

    dispatch(successNotification('Remove Corporation successful'))

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
  }
}

//addGroup
export const addGroup =
  ({ name, campaignId, transMax, crmType }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })

      let { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: CREATE_GROUP,
        variables: {
          name,
          campaignId,
          transMax,
          crmType,
          type: 'corp',
        },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { createGroup } = data
      if (!createGroup) {
        throw new Error("Can't create Rule")
      }

      dispatch(successNotification('Create Corporation successful'))

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      dispatch(errorNotification(errorMessage))
    }
  }

//setGroupList
export const setGroupList =
  ({ page, size, crmType, filters = {} }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })
      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: SET_GROUP_LIST,
        variables: { page, size, crmType, filters },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { groupsListPage } = data
      if (!groupsListPage) {
        throw new Error("Can't get group")
      }

      dispatch({
        type: types.SET_GROUP_LIST,
        data: groupsListPage,
      })

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      dispatch(errorNotification(errorMessage))
    }
  }
export const setGroupListSelect =
  ({ page, size, crmType, filters = {} }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })
      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: SET_GROUP_LIST,
        variables: { page, size, crmType, filters },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { groupsListPage } = data
      if (!groupsListPage) {
        throw new Error("Can't get group")
      }

      dispatch({
        type: types.SET_GROUP_LIST_SELECT,
        data: groupsListPage,
      })

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      dispatch(errorNotification(errorMessage))
    }
  }
