/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Input, Select, Form, DatePicker, Button, Table, InputNumber, Checkbox, Popconfirm } from 'antd'
import * as actions from '../actions'
import * as companyActions from '../../Companies/actions'
import * as sitesActions from '../../Sites/actions'
import * as processorsActions from '../../Processors/actions'
import * as productsActions from '../../Products/actions'
import * as customersActions from '../../Customers/actions'
import * as ruleActions from '../../Rules/actions'
import moment from 'moment'
import { UpdateCycle } from '../components'

const TaskListInfo = ({
  fetchCompaniesForOptions,
  updateTask,
  setTasksList,
  companiesForOptions,
  sites,
  fetchSitesList,
  fetchProcessorsList,
  setProductsList,
  customers,
  customersTotal,
  fetchCustomersList,
  deleteCycle,
  onCancel,
  setRuleProductsList,
  ruleProducts,
  setCycles,
  cycles,
  sendTask,
  record,
  products,
}) => {
  const [form] = Form.useForm()
  const [companyId, setCompanyId] = useState(record.companyId)
  const [wrongMaxDelay, setWrongMaxDelay] = useState('')
  const [crmType, setCrmType] = useState(
    window.localStorage.getItem('crm') && JSON.parse(window.localStorage.getItem('crm')).value
      ? JSON.parse(window.localStorage.getItem('crm')).value
      : 'response',
  )

  useEffect(() => {
    form.resetFields()
    //fetchCompaniesForOptions()
    fetchProcessorsList({ page: 1, size: 100, crmType: crmType })
    setProductsList({ page: 1, size: 100, crmType: crmType })
    setCycles({ page: 1, size: 100, filters: { taskId: record.id } })
    setRuleProductsList({
      page: 1,
      size: 100,
      filters: { campaignId: record.companyId },
    })
    fetchSitesList({ page: 1, size: 100, crmType: crmType })
  }, [record])

  const onFinishHandler = async values => {
    console.log('values: ', values)
    let error = ''
    if (values.minDelay > values.maxDelay) {
      error = 'min>max'
      setWrongMaxDelay('max Delay must be greater than min Delay!')
    }

    if (error === '') {
      await updateTask({
        taskId: +record.id,
        name: values.name,
        lookBackDays: values.lookBackDays ? values.lookBackDays.toString() : '',
        startDate: values.startDate,
        attempts: values.attempts,
        attemptDelay: values.attemptDelay,
        nextCycleSameAsLast: values.nextCycleSameAsLast,
        chargeCanceled: values.chargeCanceled,
        notUseBalancer: values.notUseBalancer,
        paayUse: values.paayUse,
        chargeAllProducts: values.chargeAllProducts,
        minDelay: values.minDelay,
        maxDelay: values.maxDelay,
        controlDailyCap: values.controlDailyCap,
        cascadeMidActive: values.cascadeMidActive,
        successRate: values.successRate ? values.successRate.toString() : '0',
      })
      await setTasksList({ page: 1, size: 10, filters: {} })
      onCancel()
      setWrongMaxDelay('')
      form.resetFields()
    }
  }

  const siteChange = value => {
    setRuleProductsList({ page: 1, size: 10, filters: {} })
  }

  const cycleColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'TaskID',
      dataIndex: 'taskId',
      key: 'taskId',
    },
    {
      title: 'Product ID',
      dataIndex: 'productId',
      key: 'productId',
    },
    {
      title: 'Number',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Product Price',
      dataIndex: 'productPrice',
      key: 'productPrice',
    },
    {
      title: 'Cycle Period',
      dataIndex: 'cyclePeriod',
      key: 'cyclePeriod',
    },
    {
      title: 'Actions',
      dataIndex: '',
      key: '',
      render: record => (
        <>
          <div>
            <Popconfirm
              title='Are you sure delete this Task?'
              onConfirm={e => doConfirm({ record, e })}
              onCancel={e => e?.stopPropagation()}
              okText='Yes'
              cancelText='No'
            >
              <i className='zmdi zmdi-delete zmdi-hc-2x txt-danger users-list-action_item' href='/#' title='Delete'></i>
            </Popconfirm>

            <UpdateCycle record={record} />
          </div>
        </>
      ),
    },
  ]

  const doConfirm = async ({ record, e }) => {
    e && e.stopPropagation()
    await deleteCycle({ cycleId: record.id })
    await setCycles({ page: 1, size: 100, filters: { taskId: record.taskId } })
  }

  return (
    <>
      <div className='add-processor_form-page'>
        <Form
          layout='vertical'
          hideRequiredMark
          onFinish={onFinishHandler}
          form={form}
          className='addClient_form task_form'
        >
          <div className='task_modal__select'>
            <Form.Item
              name='companyId'
              label='Select Company'
              className='task_form-item'
              initialValue={record.companyId}
            >
              {companiesForOptions.map(item => {
                if (item.id === record.companyId) {
                  return <Input placeholder={item.name} disabled />
                } else {
                  return ''
                }
              })}
            </Form.Item>

            <Form.Item className='task_form-item' name='typeTask' label='Select Type Task' initialValue={record.type}>
              <Input disabled></Input>
            </Form.Item>

            <Form.Item
              className='task_form-item'
              name='name'
              label='Task Name'
              initialValue={record.name}
              rules={[{ required: true, message: 'Incorrect name', min: 2 }]}
            >
              <Input
                style={{
                  width: '300px',
                  position: 'absolute',
                  right: '0px',
                  top: '0px',
                }}
              />
            </Form.Item>

            {record.typeTask === 'global' ? (
              <div>
                <Form.Item
                  className='task_form-item'
                  name='extSiteId'
                  label='Select Site'
                  initialValue={record.externalSiteId}
                >
                  <Select
                    onSelect={siteChange}
                    style={{
                      width: '300px',
                      position: 'absolute',
                      right: '0px',
                      top: '0px',
                    }}
                  >
                    {sites.map(item => {
                      if (+item.campaignId === +companyId) {
                        return (
                          <Select.Option key={item.id} value={item.externalId}>
                            {item.name}
                          </Select.Option>
                        )
                      }
                    })}
                  </Select>
                </Form.Item>

                <Form.Item
                  className='task_form-item'
                  name='externalProductId'
                  label='product ID'
                  initialValue={record.externalProductId ? record.externalProductId : ''}
                >
                  <Select
                    style={{
                      width: '300px',
                      position: 'absolute',
                      right: '0px',
                      top: '0px',
                    }}
                  >
                    {ruleProducts
                      ? ruleProducts.map(item => {
                          let existProduct = products.find(prod => prod.externalId === item.exProductId)
                          return (
                            <Select.Option key={item.id} value={item.exProductId}>
                              {item.exProductId} - {existProduct.name}
                            </Select.Option>
                          )
                        })
                      : ''}
                  </Select>
                </Form.Item>

                <Form.Item
                  className='task_form-item'
                  name='changeProductExId'
                  label='Search by Product'
                  initialValue={record.changeProductExId ? record.changeProductExId : ''}
                  rules={[
                    {
                      message: 'input cycle period, max 30 days',
                    },
                  ]}
                >
                  <Select
                    style={{
                      width: '300px',
                      position: 'absolute',
                      right: '0px',
                      top: '0px',
                    }}
                  >
                    {ruleProducts
                      ? ruleProducts.map(item => {
                          let existProduct = products.find(prod => prod.externalId === item.exProductId)
                          return (
                            <Select.Option key={item.id} value={item.exProductId}>
                              {item.exProductId} - {existProduct.name}
                            </Select.Option>
                          )
                        })
                      : ''}
                  </Select>
                </Form.Item>
              </div>
            ) : (
              ''
            )}

            <Form.Item
              className='task_form-item'
              name='lookBackDays'
              label='Look Back Days, days'
              initialValue={+record.lookBackDays}
              rules={[
                {
                  type: 'number',
                  min: 0,
                  max: 30,
                  message: 'input Look Back Days period, max 30 days',
                },
              ]}
            >
              <InputNumber
                style={{
                  width: '300px',
                  position: 'absolute',
                  right: '0px',
                  top: '0px',
                }}
              />
            </Form.Item>

            <div className='row_task_items'>
              <Form.Item
                name='minDelay'
                initialValue={record.minDelay}
                rules={[
                  {
                    required: true,
                    message: 'input min delay, sec',
                    min: 0,
                    max: 1800,
                    type: 'number',
                  },
                ]}
                label='Min Delay, sec'
                className='task_form-item'
              >
                <InputNumber />
              </Form.Item>

              <Form.Item
                name='maxDelay'
                initialValue={record.maxDelay}
                rules={[
                  {
                    type: 'number',
                    min: 0,
                    max: 1800,
                    required: true,
                    message: 'enter max delay, sec',
                  },
                ]}
                label='Max Delay, sec'
                className='task_form-item'
              >
                <InputNumber />
              </Form.Item>
            </div>

            <div className='row_task_items'>
              <Form.Item
                className='task_form-item'
                name='attempts'
                label='Attempts'
                initialValue={record.attempts}
                rules={[
                  {
                    type: 'number',
                    min: 1,
                    max: 5,
                    message: 'input attempts, min - 1, max - 5 ',
                  },
                ]}
              >
                <InputNumber placeholder='3' />
              </Form.Item>

              <Form.Item
                className='task_form-item'
                name='attemptDelay'
                label='Attempt Delay, days'
                initialValue={record.attemptDelay}
                rules={[
                  {
                    type: 'number',
                    min: 3,
                    max: 10,
                    message: 'input attempt delay, min - 3, max - 10 days',
                  },
                ]}
              >
                <InputNumber />
              </Form.Item>
            </div>

            <Form.Item
              className='task_form-item'
              name='successRate'
              label='Low Success Rate %'
              initialValue={record.successRate}
              rules={[
                {
                  type: 'number',
                  min: 0,
                  max: 100,
                  message: 'input Low Success Rate %, min - 0, max - 100 days',
                },
              ]}
            >
              <InputNumber />
            </Form.Item>

            <Form.Item name='nextCycleSameAsLast' valuePropName='checked' initialValue={record.nextCycleSameAsLast}>
              <Checkbox>next cycle same as last</Checkbox>
            </Form.Item>

            <Form.Item name='chargeCanceled' valuePropName='checked' initialValue={record.chargeCanceled}>
              <Checkbox>Charge Canceled Customers</Checkbox>
            </Form.Item>
            <Form.Item name='cascadeMidActive' valuePropName='checked' initialValue={record.cascadeMidActive}>
              <Checkbox>Cascade Mid</Checkbox>
            </Form.Item>

            <Form.Item name='notUseBalancer' valuePropName='checked' initialValue={record.notUseBalancer}>
              <Checkbox checked={true}>Not Use Balancer</Checkbox>
            </Form.Item>

            <Form.Item name='paayUse' valuePropName='checked' initialValue={record.paayUse}>
              <Checkbox>Use Paay</Checkbox>
            </Form.Item>

            <Form.Item name='controlDailyCap' valuePropName='checked' initialValue={record.controlDailyCap}>
              <Checkbox>Control DailyCap</Checkbox>
            </Form.Item>

            <Form.Item name='chargeAllProducts' valuePropName='checked' initialValue={record.chargeAllProducts}>
              <Checkbox>Сharge All Products</Checkbox>
            </Form.Item>

            <Form.Item
              className='task_form-item'
              name='startDate'
              label='Start Task Date'
              initialValue={moment(record.startDate)}
              validateStatus='success'
              rules={[
                {
                  required: true,
                  message: 'select date',
                },
              ]}
            >
              <DatePicker showTime />
            </Form.Item>
          </div>

          <div className='task_product__select'>
            <h4 className='txt-dart'>Cycles</h4>
            <Table columns={cycleColumns} dataSource={cycles} bordered rowKey={record => record.id} onRow={() => {}} />
          </div>

          <div className='add-new-processor_buttons'>
            <div className='task_wrong-max-delay'>{wrongMaxDelay}</div>
            <Button
              onClick={() => onCancel()}
              className='addClient_form-button'
              type='primary'
              style={{
                background: '#1890ff',
                color: '#fff',
                borderRadius: '5px',
                padding: '5px',
                height: '40px',
                width: '100px',
                fontWeight: 'bold',
                marginRight: '5px',
              }}
            >
              <a>
                <i className='zmdi zmdi-arrow-left mr-10'></i>
                Back
              </a>
            </Button>
            <Button
              className='addClient_form-button'
              type='submit'
              style={{
                background: 'green',
                color: '#fff',
                borderRadius: '5px',
                padding: '5px',
                height: '40px',
                width: '100px',
                fontWeight: 'bold',
                marginRight: '5px',
              }}
              htmlType='submit'
            >
              <div>
                <i className='zmdi zmdi-plus mr-10' />
                Update
              </div>
            </Button>
          </div>
        </Form>
      </div>
    </>
  )
}

const mapStateToProps = store => ({
  companiesForOptions: store.companies.companiesForOptions,
  sites: store.sites.list,
  cycles: store.tasks.cycles,
  processor: store.processors.list,
  products: store.products.list,
  customers: store.customers.list,
  customersTotal: store.customers.total,
  ruleProducts: store.rules.ruleProductslist,
})

const mapDispatchToProps = {
  addNewTask: actions.addNewTask,
  setTasksList: actions.setTasksList,
  setCycles: actions.setCycles,
  fetchCompaniesForOptions: companyActions.fetchCompaniesForOptions,
  fetchSitesList: sitesActions.fetchSitesList,
  fetchProcessorsList: processorsActions.fetchProcessorsList,
  setProductsList: productsActions.setProductsList,
  fetchCustomersList: customersActions.fetchCustomersList,
  updateTask: actions.updateTask,
  createCycle: actions.createCycle,
  sendTask: actions.sendTask,
  deleteCycle: actions.deleteCycle,
  setRuleProductsList: ruleActions.setRuleProductsList,
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskListInfo)
