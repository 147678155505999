import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Input, Select, Form, DatePicker, Button, Space, InputNumber, Checkbox, Table } from 'antd'
import * as actions from '../actions'
import * as companyActions from '../../Companies/actions'
import * as sitesActions from '../../Sites/actions'
import * as processorsActions from '../../Processors/actions'
import * as productsActions from '../../Products/actions'
import * as customersActions from '../../Customers/actions'
import * as ruleActions from '../../Rules/actions'
import AddCsv from '../../Customers/components/AddCsv'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import moment from 'moment-timezone'
import 'moment/locale/zh-cn'

const AddNewTask = ({
  fetchCompaniesList,
  sendTask,
  createCycle,
  setTasksList,
  tasks,
  addNewTask,
  campaigns,
  sites,
  fetchSitesList,
  fetchProcessorsList,
  setProductsList,
  products,
  onCancel,
  setRuleProductsList,
  ruleProducts,
  totalCsvCustomers,
  csvCustomers,
  removeCsvCustomers,
}) => {
  const [form] = Form.useForm()
  const [taskName, setTaskName] = useState('')
  const [companyId, setCompanyId] = useState(0)
  const [siteProducts, setSiteProducts] = useState([])
  const [wrongMaxDelay, setWrongMaxDelay] = useState('')
  const [taskType, setTaskType] = useState('')
  const [pageSize, setPageSize] = useState(10)
  const [pageNumber, setPageNumber] = useState(1)
  const [siteForCustomer, setSiteForCustomer] = useState('')
  const [productsData, setProductsData] = useState([])
  const [tasksNames, setTasksNames] = useState([])
  const [notUseBalancer, setNotUseBalancer] = useState(false)
  const [crmType, setCrmType] = useState(
    window.localStorage.getItem('crm') && JSON.parse(window.localStorage.getItem('crm')).value
      ? JSON.parse(window.localStorage.getItem('crm')).value
      : 'response',
  )
  useEffect(() => {
    fetchCompaniesList({ page: 1, size: 100, crmType: crmType })
    fetchProcessorsList({ page: 1, size: 100, crmType: crmType })
    removeCsvCustomers()
  }, [])

  useEffect(() => {
    let tasksN = []
    setTasksList({ page: 1, size: 10, crmType: crmType, filters: {} })
    removeCsvCustomers()
    // for create different names tasks
    tasks.forEach(task => {
      tasksN.push(task.name)
    })
    setTasksNames(tasksN)
  }, [tasks.length])

  useEffect(() => {
    fetchSitesList({ page: 1, size: 100, crmType: crmType, filters: { campaignId: companyId } })
    setProductsList({ page: 1, size: 500, crmType: crmType, filters: { campaignId: companyId } })
  }, [companyId])

  useEffect(() => {
    let dataArray = []
    setProductsData([])
    for (const item in csvCustomers) {
      dataArray.push({
        amount: csvCustomers[item].amount,
        productName: csvCustomers[item].productName,
        productId: csvCustomers[item].productId,
        type: csvCustomers[item].productType,
      })
    }
    dataArray = dataArray.filter((thing, index, self) => index === self.findIndex(t => t.productId === thing.productId))
    setProductsData(dataArray)
  }, [csvCustomers])

  useEffect(() => {
    let formFieldsData = {}
    for (let i = 0; i < productsData.length; i++) {
      let cycleIndex = `cycle${i}`
      formFieldsData[cycleIndex] = [
        {
          number: 1,
          price: productsData[i].amount,
          period: 30,
          productId: productsData[i].productId,
          type: productsData[i].type,
        },
      ]
    }
    form.setFieldsValue(formFieldsData)
  }, [productsData])

  const onFinishHandler = async values => {
    let error = ''

    for (const prop in values) {
      for (let i = 0; i < 10; i++) {
        if (prop === `cycle${i}`) {
          if (!values[prop] || values[prop].length === 0) {
            error = 'need input min 1 cycle'
            setWrongMaxDelay('need input min 1 cycle for each product!')
          } else {
            for (let i = 0; i < values[prop].length; i++) {
              if (values[prop][i].price) {
                let okPrice = /^[\d\,\.]*$/.test(values[prop][i].price)
                if (!okPrice) {
                  error = 'wrong price'
                  setWrongMaxDelay('incorrect cycle price!')
                }
              }
            }
          }
        }
      }
    }

    if (!notUseBalancer) {
      for (const prop in values) {
        for (let i = 0; i < 10; i++) {
          if (prop === `cycle${i}`) {
            let findProduct = ruleProducts.find(item => {
              return item.exProductId === values[prop][0].productId
            })
            if (!findProduct) {
              error = 'wrong product'
              setWrongMaxDelay('wrong product!')
            }
          }
        }
      }
    }

    if (values.minDelay > values.maxDelay) {
      error = 'min>max'
      setWrongMaxDelay('max Delay must be greater than min Delay!')
    }
    if (csvCustomers.length === 0 && values.typeTask === 'custom') {
      error = 'customers are missing'
      setWrongMaxDelay('customers are missing!')
    }
    if (error === '') {
      const createdTask = await addNewTask({ ...values })
      if (createdTask) {
        const createdCycle = await createCycle({ ...values })
        if (createdCycle) {
          const responseSendTask = await sendTask({
            taskName,
            csvCustomers: csvCustomers,
          })
          if (responseSendTask) {
            form.resetFields()
            for (let i = 0; i < 20; i++) {
              form.resetFields([`cycle${i}`])
            }
            setTasksList({ page: 1, size: 10, filters: {} })
            setWrongMaxDelay('')
            removeCsvCustomers()
            setTaskType('')
            setProductsData([])
            setNotUseBalancer(false)
            setTaskName('')
            onCancel()
          }
        }
      }
    }
  }

  const changeTaskName = value => {
    const differentName = tasksNames.filter(name => {
      return name === value.target.value
    })
    if (differentName.length > 0) {
      setWrongMaxDelay('task with this name exist')
    } else {
      setWrongMaxDelay('')
      setTaskName(value.target.value)
    }
  }

  const companyChange = value => {
    form.resetFields(['extSiteId', 'typeTask', 'externalProductId'])
    setCompanyId(value)
    setRuleProductsList({ page: 1, size: 100, filters: { campaignId: +value } })
  }

  const typeChange = value => {
    setTaskType(value)
  }

  const selectedSite = value => {
    setSiteForCustomer(value)
    const existProducts = []
    for (let i = 0; i < ruleProducts.length; i++) {
      let product = products.find(item => item.externalId == ruleProducts[i].exProductId)
      existProducts.push(product)
    }
    setSiteProducts(existProducts)
  }

  const onChangeNotUseBalancer = value => {
    if (value.target.checked) {
      setNotUseBalancer(true)
    } else {
      setNotUseBalancer(false)
    }
  }

  const columns = [
    {
      title: 'Customer ID',
      dataIndex: 'externalId',
      key: 'externalId',
    },
    {
      title: 'Site ID',
      dataIndex: 'externalSiteId',
      key: 'externalSiteId',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Product ID',
      dataIndex: 'productId',
      key: 'productId',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Product Type',
      dataIndex: 'productType',
      key: 'productType',
    },
    {
      title: 'Recurring Id',
      dataIndex: 'recurringId',
      key: 'pecurringId',
    },
    {
      title: 'Сascade Mid',
      dataIndex: 'cascadeMid',
      key: 'cascadeMid',
    },
    {
      title: 'Campaign Name',
      dataIndex: 'campaignId',
      key: 'campaignId',
      render: record => {
        let name = ''
        campaigns.map(item => {
          if (item.id == record) {
            name = item.name
          }
        })
        return (
          <div>
            {name} ({record})
          </div>
        )
      },
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
  ]

  const changePaginataion = data => {
    const { current, pageSize: paginationSize } = data
    setPageSize(paginationSize)
    setPageNumber(current)
  }

  const changeProduct = values => {
    let dataArray = []
    products.map((product, key) => {
      let existProduct = products.find(item => item.externalId === values[key])
      if (existProduct) {
        dataArray.push({
          amount: existProduct.amount,
          productName: existProduct.name,
          productId: existProduct.externalId,
          type: existProduct.type,
        })
      }
    })
    setProductsData(dataArray)
  }

  return (
    <>
      <div className='add-processor_form-page'>
        <Form
          layout='vertical'
          hideRequiredMark
          onFinish={onFinishHandler}
          form={form}
          className='addClient_form task_form'
        >
          <div className='task_modal__select'>
            <Form.Item
              name='companyId'
              className='task_form-item'
              label='Select Company'
              rules={[{ required: true, message: 'Please select company!' }]}
            >
              <Select onChange={companyChange}>
                {campaigns.map(item => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              className='task_form-item'
              name='typeTask'
              label='Select Type Task'
              rules={[{ required: true, message: 'Please select type!' }]}
            >
              <Select onSelect={typeChange}>
                <Select.Option key={1} value={'global'}>
                  Global
                </Select.Option>
                <Select.Option key={2} value={'custom'}>
                  Custom
                </Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              className='task_form-item'
              name='name'
              label='Task Name'
              rules={[{ required: true, message: 'Incorrect name', min: 5 }]}
            >
              <Input onChange={changeTaskName} />
            </Form.Item>

            {taskType === 'global' ? (
              <>
                <Form.Item
                  className='task_form-item'
                  name='extSiteId'
                  label='Select Site'
                  rules={[{ required: true, message: 'Please select site!' }]}
                >
                  <Select onChange={selectedSite}>
                    {sites.map(item => {
                      if (item.campaignId === companyId) {
                        return (
                          <Select.Option key={item.id} value={item.externalId}>
                            ({item.externalId}) {item.name}
                          </Select.Option>
                        )
                      }
                    })}
                  </Select>
                </Form.Item>

                <Form.Item
                  className='task_form-item'
                  name='externalProductId'
                  label='product ID'
                  rules={[
                    {
                      required: true,
                      message: 'Select Product ID',
                    },
                  ]}
                >
                  <Select mode='multiple' placeholder='Select Product' onChange={changeProduct}>
                    {siteProducts.map(item => {
                      let existProduct = products.find(product => product.externalId === item.externalId)
                      return (
                        <Select.Option key={item.externalId} value={item.externalId}>
                          {existProduct.name} ({existProduct.externalId})
                        </Select.Option>
                      )
                    })}
                  </Select>
                </Form.Item>

                <Form.Item
                  className='task_form-item'
                  name='lookBackDays'
                  label='Look Back Days, days'
                  rules={[
                    {
                      type: 'number',
                      min: 0,
                      max: 90,
                      message: 'input Look Back Days period, max 30 days',
                    },
                  ]}
                >
                  <InputNumber />
                </Form.Item>
              </>
            ) : (
              ''
            )}

            <div className='row_task_items'>
              <Form.Item
                name='minDelay'
                rules={[
                  {
                    required: true,
                    message: 'input min delay, sec',
                    min: 0,
                    max: 1800,
                    type: 'number',
                  },
                ]}
                label='Min Delay, sec'
                className='task_form-item'
              >
                <InputNumber />
              </Form.Item>

              <Form.Item
                name='maxDelay'
                rules={[
                  {
                    type: 'number',
                    min: 0,
                    max: 1800,
                    required: true,
                    message: 'enter max delay, sec',
                  },
                ]}
                label='Max Delay, sec'
                className='task_form-item'
              >
                <InputNumber />
              </Form.Item>
            </div>

            <div className='row_task_items'>
              <Form.Item
                className='task_form-item'
                name='attempts'
                label='Attempts'
                rules={[
                  {
                    type: 'number',
                    min: 1,
                    max: 5,
                    message: 'input attempts, min - 1, max - 5 ',
                  },
                ]}
              >
                <InputNumber placeholder='1' />
              </Form.Item>

              <Form.Item
                className='task_form-item'
                name='attemptDelay'
                label='Attempt Delay, days'
                rules={[
                  {
                    type: 'number',
                    min: 3,
                    max: 10,
                    message: 'input attempt delay, min - 3, max - 10 days',
                  },
                ]}
              >
                <InputNumber placeholder='7' />
              </Form.Item>
            </div>

            <Form.Item
              className='task_form-item'
              name='successRate'
              label='Low Success Rate %'
              rules={[
                {
                  type: 'number',
                  min: 0,
                  max: 100,
                  message: 'input Low Success Rate %, min - 0, max - 100 days',
                },
              ]}
            >
              <InputNumber />
            </Form.Item>

            <Form.Item name='nextCycleSameAsLast' valuePropName='checked'>
              <Checkbox>Next Cycle Same As Last</Checkbox>
            </Form.Item>

            <Form.Item name='chargeCanceled' valuePropName='checked'>
              <Checkbox>Charge Cancleled Customers</Checkbox>
            </Form.Item>

            <Form.Item name='controlDailyCap' valuePropName='checked'>
              <Checkbox>Control DailyCap</Checkbox>
            </Form.Item>
            <Form.Item name='cascadeMidActive' valuePropName='checked'>
              <Checkbox>Cascade Mid</Checkbox>
            </Form.Item>

            <Form.Item name='notUseBalancer' valuePropName='checked'>
              <Checkbox onChange={onChangeNotUseBalancer}>Not Use Balancer</Checkbox>
            </Form.Item>

            {notUseBalancer ? (
              <Form.Item name='chargeAllProducts' valuePropName='checked'>
                <Checkbox defaultChecked>Сharge All Products</Checkbox>
              </Form.Item>
            ) : (
              ''
            )}

            <Form.Item name='paayUse' valuePropName='checked'>
              <Checkbox>Use Paay</Checkbox>
            </Form.Item>

            <Form.Item className='task_form-item' name='startDate' label='Start Task Date' validateStatus='success'>
              <DatePicker showTime placeholder={moment().tz('America/New_York').format('YYYY-MM-DD HH:mm:ss ZZ')} />
            </Form.Item>
          </div>

          <div className='task_product__select'>
            <div className={'task_product__cycles'}>
              {productsData.map((product, key) => {
                let listName = `cycle${key}`
                let productAmount = product.amount
                return (
                  <Form.List name={listName} key={product.productId}>
                    {(fields, { add, remove }) => {
                      return (
                        <div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            Search by product - {product.productName}({product.productId})({productAmount})
                          </div>
                          {/* <Form.Item
                          className="task_form-item"
                          name="changeProduct"
                          label="Change Product"
                          rules={[
                            { required: true, message: 'Please select site!' },
                          ]}
                        >
                          <Select
                            defaultValue="lucy"
                            style={{
                              width: '300px',
                              position: 'absolute',
                              right: '0px',
                              top: '0px',
                            }}
                          >
                            {siteProductsList.map((item, k) => {
                              return (
                                <Select.Option
                                  key={item.externalProductId + k}
                                  value={item.changeExternalProductId}
                                >
                                  {item.changeExternalProductId}
                                </Select.Option>
                              )
                            })}
                          </Select>
                        </Form.Item> */}
                          {fields.map((field, index) => {
                            return (
                              <Space
                                key={field.key}
                                style={{ display: 'flex', marginBottom: 8 }}
                                align='start'
                                className='task_cycle-form'
                              >
                                <Form.Item
                                  {...field}
                                  name={[field.name, 'number']}
                                  fieldKey={[field.fieldKey, 'number']}
                                  label='cycle'
                                  className='ask_modal-item__small'
                                  initialValue={index + 1}
                                >
                                  <Input disabled placeholder={index + 1} />
                                </Form.Item>

                                <Form.Item
                                  {...field}
                                  name={[field.name, 'price']}
                                  fieldKey={[field.fieldKey, 'price']}
                                  label='Price'
                                  className='ask_modal-item__small'
                                  initialValue={productAmount}
                                  rules={[
                                    {
                                      min: 0,
                                      message: 'input price',
                                    },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>

                                <Form.Item
                                  {...field}
                                  name={[field.name, 'period']}
                                  fieldKey={[field.fieldKey, 'period']}
                                  label='Period'
                                  className='ask_modal-item__small'
                                  rules={[
                                    {
                                      type: 'number',
                                      min: 0,
                                      max: 30,
                                      message: 'input period, max 30 days',
                                    },
                                  ]}
                                >
                                  <InputNumber placeholder={'30'} />
                                </Form.Item>

                                <Form.Item
                                  {...field}
                                  name={[field.name, 'productId']}
                                  fieldKey={[field.fieldKey, 'productId']}
                                  initialValue={product.productId}
                                  hidden={true}
                                >
                                  <InputNumber />
                                </Form.Item>

                                <Form.Item
                                  {...field}
                                  name={[field.name, 'type']}
                                  fieldKey={[field.fieldKey, 'type']}
                                  initialValue={product.type}
                                  hidden={true}
                                >
                                  <InputNumber />
                                </Form.Item>

                                <MinusCircleOutlined
                                  onClick={() => {
                                    remove(index)
                                  }}
                                />
                              </Space>
                            )
                          })}

                          <Form.Item>
                            <Button
                              type='dashed'
                              onClick={() => {
                                add()
                              }}
                              block
                            >
                              <PlusOutlined /> Add Cycle
                            </Button>
                          </Form.Item>
                        </div>
                      )
                    }}
                  </Form.List>
                )
              })}
            </div>

            {taskType === 'custom' && taskName.length > 5 && !wrongMaxDelay ? (
              <div style={{ paddingBottom: '30px' }}>
                <h4 className='txt-dark'>Customers list</h4>
                <div className='customer-list_select-item'>
                  <AddCsv siteForCustomer={siteForCustomer} taskName={taskName} />
                  <button
                    style={{
                      background: '#1890ff',
                      color: '#fff',
                      borderRadius: '5px',
                      padding: '5px',
                      height: '40px',
                      width: '150px',
                      fontWeight: 'bold',
                      marginRight: '5px',
                    }}
                    onClick={() => {
                      removeCsvCustomers()
                      setProductsData([])
                    }}
                  >
                    Clear The List
                  </button>
                </div>

                <Table
                  columns={columns}
                  dataSource={csvCustomers}
                  bordered
                  onChange={changePaginataion}
                  rowKey={record => record.id}
                  scroll={{ x: 'calc(900px + 50%)', y: 440 }}
                  pagination={{
                    showSizeChanger: true,
                    pageSize,
                    current: pageNumber,
                    totalCsvCustomers,
                  }}
                />
              </div>
            ) : (
              ''
            )}
          </div>

          <div className='add-new-processor_buttons button_position_fixed'>
            <div className='task_wrong-max-delay'>{wrongMaxDelay}</div>
            <Button
              onClick={() => onCancel()}
              className='addClient_form-button'
              type='primary'
              style={{
                background: '#1890ff',
                color: '#fff',
                borderRadius: '5px',
                padding: '5px',
                height: '40px',
                width: '100px',
                fontWeight: 'bold',
                marginRight: '5px',
              }}
            >
              <a>
                <i className='zmdi zmdi-arrow-left mr-10'></i>
                Back
              </a>
            </Button>
            <Button
              type='submit'
              style={{
                background: 'green',
                color: '#fff',
                borderRadius: '5px',
                padding: '5px',
                height: '40px',
                width: '100px',
                fontWeight: 'bold',
                marginRight: '5px',
              }}
              htmlType='submit'
            >
              <div
                onClick={() => {
                  // setTimeout((wrongMaxDelay) => {
                  //   if (!wrongMaxDelay) {
                  //     sendTask({ taskName, csvCustomers: csvCustomers })
                  //   }
                  // }, 3000)
                }}
              >
                <i className='zmdi zmdi-plus mr-10' />
                Create
              </div>
            </Button>
          </div>
        </Form>
      </div>
    </>
  )
}

const mapStateToProps = store => ({
  campaigns: store.companies.list,
  tasks: store.tasks.list,
  sites: store.sites.list,
  processor: store.processors.list,
  products: store.products.list,
  customers: store.customers.list,
  csvCustomers: store.customers.csvCustomers,
  totalCsvCustomers: store.customers.totalCsvCustomers,
  ruleProducts: store.rules.ruleProductslist,
})

const mapDispatchToProps = {
  addNewTask: actions.addNewTask,
  setTasksList: actions.setTasksList,
  fetchCompaniesList: companyActions.fetchCompaniesList,
  fetchSitesList: sitesActions.fetchSitesList,
  fetchProcessorsList: processorsActions.fetchProcessorsList,
  setProductsList: productsActions.setProductsList,
  fetchCustomersList: customersActions.fetchCustomersList,
  sendTask: actions.sendTask,
  createCycle: actions.createCycle,
  setRuleProductsList: ruleActions.setRuleProductsList,
  removeCustomer: customersActions.removeCustomer,
  removeCsvCustomers: customersActions.removeCsvCustomers,
}

export default connect(mapStateToProps, mapDispatchToProps)(AddNewTask)
