import { v4 as uuid } from 'uuid'

export default [
  {
    id: uuid(),
    name: 'Clients',
    icon: 'zmdi zmdi-accounts mr-20',
    adminOnly: true,
    href: 'clients-list',
    // subRoutes: [
    //   {
    //     id: uuid(),
    //     name: 'Clients List',
    //     href: 'clients-list',
    //   },
    //   {
    //     id: uuid(),
    //     name: 'Add Client',
    //     href: 'add-client',
    //   },
    // ],
  },
  {
    id: uuid(),
    name: 'Companies',
    icon: 'zmdi zmdi-layers mr-20',
    href: 'companies',
  },
  {
    id: uuid(),
    name: 'Sites',
    icon: 'zmdi zmdi-view-subtitles mr-20',
    href: 'site-list',
  },
  {
    id: uuid(),
    name: 'Processor Configuration',
    icon: 'zmdi zmdi-memory mr-20',
    href: '/processors',
  },
  {
    id: uuid(),
    name: 'Products',
    icon: 'zmdi zmdi-mall mr-20',
    href: 'products',
  },
  {
    id: uuid(),
    name: 'Pixels',
    icon: 'zmdi zmdi-mall mr-20',
    href: 'pixels',
  },
  {
    id: uuid(),
    name: 'Scrub',
    icon: 'zmdi zmdi-mall mr-20',
    href: 'scrub',
  },
  {
    id: uuid(),
    name: 'Corporations',
    icon: 'zmdi zmdi-group mr-20',
    title: 'Corporations rules',
    href: 'corporations',
  },
  {
    id: uuid(),
    name: 'Balancers for tasks',
    icon: 'zmdi zmdi-group mr-20',
    title: 'Balancers for tasks',
    href: 'groups',
  },
  {
    id: uuid(),
    name: 'Tasks',
    icon: 'zmdi zmdi-fast-forward mr-20',
    href: '/',
  },
  {
    id: uuid(),
    name: 'RebatesCampaigns',
    icon: 'zmdi zmdi-fast-forward mr-20',
    href: '/rebatesCampaigns',
  },
]
